import React, { useEffect, useContext, useState, useRef } from "react";
import DesignEditor from "../../atoms/DesignEditor/DesignEditor";
import ShowRoom2Template from "../ShowRoom2Template";
import Editor from "../../atoms/Editor/Editor";
import CreateShowroomMutation from "graphql/mutations/CreateShowroomMutation";
import EditShowroomMutation from "graphql/mutations/EditShowroomMutation";
import { toast } from "react-toastify";
import { withRouter } from "../../../../routes/withRouter";
import { showErrorToast, showSuccessToast } from "@master-wizr/toast";
import { useLocation, useNavigate } from "react-router-dom";
import StickyNav from "containers/NavBar";
import { TemplateContext } from "context/TemplateContentContext";
import { showroomTemplates } from "constants/templates";
import { getClientData } from "helpers/queryHandler";
import clientOnly from "graphql/queries/clientOnly";
import { SUPER_ROLES, PORTAL_ROLES, STATIC_URL } from "constants/env";
import { useQuery } from "relay-hooks";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./style.scss";
import {wordFile, excelFile, powerpointFile, pdfFile, videoFile, links} from "./../../../../helpers/checkTypes";
import {ReactComponent as Close} from "assets/images/close.svg";
import {ReactComponent as Reorder} from "assets/images/reorder-icon.svg";
import {ReactComponent as MPublisher} from "assets/images/mpublisherLogo.svg";
import { items } from "containers/NavBar/items";
import responseHandler from "helpers/responseHandler";
import { uploadFilesAndDeleteByUrl } from "helpers/fileUpload";
import { fileUploadPrefixes } from "constants/others";
import videoIcon from '../../../../assets/images/video-player-icon.jpg';
import UploadModal from "containers/Modals/UploadModal";
import Loader from "./Loader";
import { backgroundImages } from "constants/backgroundImages";
import { useTour } from "@reactour/tour";
import ImgModal from "components/ui/atoms/ImgModal/ImgModal";
import { MPUBLISHER_IMAGES } from "constants/backgroundImages";
import useWindowDimensions from "components/ui/atoms/windowDimensions";
import useOrientation from "components/ui/atoms/windowDimensions/orientation";
import { HiOutlinePresentationChartBar } from "react-icons/hi";
import { useTranslation } from "react-i18next";
export function ShowRoomDesign(props) {
  const { context, setContext } = useContext(TemplateContext);
  const { t } = useTranslation();
  const [preview, setPreview] = useState(Object.keys(props.showroomData?.showroom || {}).length === 0 ? false : true);
  const [showPopUp, setShowPopUp] = useState(false);
  const [mpublisherBackgrounds, setMpublisherBackgrounds] = useState(false);
  const location = useLocation();
  const [activeIndex, setActiveIndex] = useState(-1);
  const [activeImage, setActiveImage] = useState('');
  const dimensions  = useWindowDimensions();
  const orientation = useOrientation();
  const [handleBg,setHandleBg] = useState(false);
const [presentationMode, setPresentationMode] = useState(false);
  const portalRole = getClientData(
    useQuery,
    clientOnly,
    {},
    "Portal"
  )?.portalRole;
  const accountRole = getClientData(
    useQuery,
    clientOnly,
    {},
    "Profile"
  )?.accountRole;
  const portal = getClientData(useQuery, clientOnly, {}, "Portal");
  const profile = getClientData(useQuery, clientOnly, {}, "Profile");
  const [unAuthorized, setUnAuthorized] = useState(false);
  const [imageChangesQueue, setImageChangesQueue] = useState([]);
  const [imageChangesQueueBg, setImageChangesQueueBg] = useState([]);
  const [reorderMode, setReorderMode] = useState(false);
  const [reorderingRow, setReorderingRow] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [bgModal,setBgModal] = useState(false);
  const navigate = useNavigate();
  const {setCurrentStep, currentStep } = useTour();
  const modals = getClientData(useQuery, clientOnly, {}, "Modal");
  useEffect(() => {
    // if (JSON.stringify(context.showroomDetails) === "{}") {
    //   navigate("/templates");
    // }
    if (
      !(PORTAL_ROLES.includes(portalRole) || SUPER_ROLES.includes(accountRole))||portalRole==="PUBLISHER"
    ) {
      setUnAuthorized(true);
      setPreview(true);
    }
    setContext({
      ...context,
      showroomDetails: {
        ...context.showroomDetails,
        templateId: parseInt(props.templateId),
      },
      tag: ""
    });
    if (props.showroomData?.showroom) {
      if (props.showroomData.showroom.content) {
        setContext({
          ...context,
          showroomDetails: {
            ...context.showroomDetails,
            mainHeading: props.showroomData.showroom.mainHeading,
            subHeading: props.showroomData.showroom.subHeading,
            content: props?.showroomData?.showroom?.content,
            headerImage: props.showroomData.showroom.headerImage,
            backgroundImage: props?.showroomData?.showroom?.backgroundImage,
            tags: props.showroomData.showroom.tags,
            _id: props.showroomData.showroom._id,
            name: props.showroomData.showroom.name,
            portalId: portal?.portalId,
            creator: profile?.userId,
            accountId: profile?.accountId,
            templateId: parseInt(props.templateId),
          },
        });
      } else {
        if (JSON.stringify(context.showroomDetails) === "{}") {
          navigate("/showroom-templates");
        }
        setContext({
          ...context,
          showroomDetails: {
            ...context.showroomDetails,
            mainHeading: props.showroomData.showroom.mainHeading,
            subHeading: props.showroomData.showroom.subHeading,
            headerImage: null,
            backgroundImage: null,
            tags: props.showroomData.showroom.tags,
            _id: props.showroomData.showroom._id,
            name: props.showroomData.showroom.name,
            portalId: portal?.portalId,
            creator: profile?.userId,
            accountId: profile?.accountId,
            templateId: parseInt(props.templateId),
          },
        });
      }
    }
    return () => {
      setContext({
        ...context,
        showroomDetails: {},
      });
    };
  }, [props?.showroomData]);

  const onChangeEditor = editorValue => content => {
    const index = editorValue?.split("-")[1];
    const stringWithoutFirstThree = content && content.length >= 3 ? content.substring(3) : '';
    const noTagsContent = stringWithoutFirstThree.slice(0, -4);
    switch (editorValue) {
      case "mainHeading":
        if (context.showroomDetails.mainHeading !== content) {
          setContext({
            ...context,
            showroomDetails: {
              ...context.showroomDetails,
              mainHeading: content,
              name: Object.keys(props.showroomData?.showroom).length === 0 ? noTagsContent : context.showroomDetails.name,
            },
            isEdited: true,
          });
        }
        break;
      case "subHeading":
        if (context.showroomDetails !== content) {
          setContext({
            ...context,
            showroomDetails: {
              ...context.showroomDetails,
              subHeading: content,
            },
            isEdited: true,
          });
        }
        break;
      case `body-${index}`:
        let rowNum = Number(index?.split("&")[0]);
        let indexNum = Number(index?.split("&")[1]);
        if (
          context?.showroomDetails?.content[rowNum]?.content[indexNum]
            .subHeading !== content
        ) {
          let mainContent = context.showroomDetails.content;
          let rowItems = [...context.showroomDetails.content[rowNum].content];
          let rowItem = {
            ...rowItems[indexNum],
            subHeading: content,
          };
          rowItems[indexNum] = rowItem;
          let mainContentRow1 = {
            ...context.showroomDetails.content[0],
            content: rowItems,
          };
          let contentCopy = [...mainContent];
          contentCopy[rowNum] = mainContentRow1;
          setContext({
            ...context,
            showroomDetails: {
              ...context.showroomDetails,
              content: [...contentCopy],
            },
            isEdited: true,
          });
        }
        break;

      default:
        break;
    }
  };

  const getText = (subHeading) =>{
    if(subHeading!==''){
      
    const element = document.createElement("div");
    element.innerHTML = subHeading;

    return element.textContent || element.innerText || "";
    }else{
      return ""
    }
  } 

  const handleKeyDown = e => {
    let val = e.target.value;
    let list = context.showroomDetails?.tags
      ? JSON.parse(JSON.stringify(context.showroomDetails?.tags))
      : [];

    if (e.key === "Enter" && val.length > 1 && !list?.includes(val)) {
      list.push(e.target.value);
      e.target.value = "";
      setContext({
        ...context,
        showroomDetails: {
          ...context.showroomDetails,
          tags: list,
        },
        tag: ""
      });
    }
    else if(e.key === "Enter" && val.length > 1 && list?.includes(val)){
        return showErrorToast(`Tag already exists`);
    }
  };

  const handleRemoveTag = i => {
    let list = context.showroomDetails.tags;
    const updatesList = list.filter(tag => tag !== i);
    setContext({
      ...context,
      showroomDetails: {
        ...context.showroomDetails,
        tags: updatesList,
      },
    });
  };

  const onChangeMetaData = field => e => {
    let value = e.target.value;
    switch (field) {
      case "name":
        if (context.name !== value) {
          setContext({
            ...context,
            showroomDetails: {
              ...context.showroomDetails,
              name: value,
            },
          });
        }
        break;
      case "link":
        if (context.showroomDetails.link !== value) {
          setContext({
            ...context,
            showroomDetails: {
              ...context.showroomDetails,
              link: value,
            },
          });
        }
        break;
      default:
        break;
    }
  };

  const handleMutation = async () => {
    if(context.tag) return setShowPopUp(true)
    let showroom = context.showroomDetails;
    props.templateId >= 5 && props.templateId <= 7 && uploadHeader();
    if (!portal) {
      return toast.error("Select portal", {
        theme: "dark",
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    showroom = {...showroom, portalId: portal.portalId};   
    delete showroom["_id"];
    delete showroom["creator"];
   //change thumbnail in creation of showrooms.
    if(!imageChangesQueue.length) {
      showroom?.content &&
      showroom?.content.forEach(element => {
        element.content.forEach(item => {
          delete item?.elementsOfSlider;
        });
      });
    } else {
      const {uploadedResult : result} = await uploadFilesAndDeleteByUrl(imageChangesQueue, fileUploadPrefixes.presentationStudioThumbnail)
      if(result.length === 0){
      }else{
        const urls = result;
        let contentArr = [] ;
        let presObj = {};
        let row={}
        let rows = [...showroom?.content];
        for(let index = 0; index < imageChangesQueue.length; index++){
          const image = imageChangesQueue[index];
          let url = "";
          for(let i = 0; i < urls.length; i++){
            if(urls[i]?.name === image?.blob?.tempName){
              url = urls[i].fileUrl;
            };
          }
          const {rowIndex} = image.itemData;
          const imgId = image.id;
          
          row = {...showroom?.content[parseInt(rowIndex.split("&")[0])]}
          contentArr = [...row.content]
          for(let j = 0; j<contentArr.length; j++){
            if(contentArr[j].presentationId.toString() === imgId.toString()){
              presObj = {...contentArr[j]};
              presObj.imageUrl = url;
              contentArr[j] = presObj;
              row.content = contentArr;
              rows[parseInt(rowIndex.split("&")[0])] = row;
              showroom.content = rows;
              
            }
          }
        }
      }
   }
    if (!showroom.content) showroom.content = [];
    showroom.templateId = parseInt(location.pathname.split("/")[2]);

    let errors = "";
    if (!showroom.mainHeading) {
      errors += "Title, ";
    }
    if (!showroom.subHeading) {
      errors += "SubHeading, ";
    }
    if (!showroom.name) {
      errors += "Name, ";
    }
    if (errors !== "") {
      return showErrorToast(`${errors} required`);
    }
    setIsLoading(true)

    CreateShowroomMutation(showroom, response => {
      responseHandler(response, "Showroom Created Successfully", () =>{
        props.navigate("/showrooms");
      })
      setIsLoading(false);
    });
  };

  const [nameInputValue, setNameInputValue] = useState("");

  useEffect(() => {
    setContext((prevContext) => ({
      ...prevContext,
      showroomDetails: {
        ...prevContext.showroomDetails,
        name: nameInputValue,
      },
    }));
  }, [nameInputValue]);

  const handleNameInputChange = (event) => {
    setNameInputValue(event.target.value);
  };

  const handleEditTemplate = () => {
    props.templateId >= 5 && props.templateId <= 7 && uploadHeader();
    let content = JSON.parse(JSON.stringify(context.showroomDetails.content));
    content.forEach(item => {
      item.content.forEach(item2 => {
        delete item2.files;
      });
    });

    context.showroomDetails.content = content;
    const {
      _id: editShowroomId,
      accountId,
      updatedAt,
      templateId,
      createdAt,
      creator,
      headerImage,
      ...showroom
    } = context.showroomDetails;

    let errors = "";
    if (!showroom.mainHeading) {
      errors += "Title, ";
    }
    if (!showroom.subHeading) {
      errors += "SubHeading,";
    }
    if (errors !== "") {
      return showErrorToast(`${errors} required`);
    }

    EditShowroomMutation(
      editShowroomId,
      { ...showroom, headerImage: context.showroomDetails.headerImage },
      response => {
        responseHandler(response, "Showroom Updated Successfully", () =>{
          props.navigate(-1);

        });
      }
    );
    setContext({
      ...context,
      edited: false,
    });
  };

  const uploadHeader = async uploadImageHeader => {
    uploadImageHeader && (await uploadImageHeader());
  };

  const onClickPreview = () => {
    setPreview(!preview);
  };

  const getImage = (imgUrl) => {
    if(wordFile.includes(imgUrl.split('.').pop())){
      return "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1661290121/studio%20visuals/wordthumbnail_klkmyr.png";
    }
    else if(powerpointFile.includes(imgUrl.split('.').pop()) ){
      return "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1661290121/studio%20visuals/pptthumbnail_jqrrk9.png";
    }
    else if(pdfFile.includes(imgUrl.split('.').pop())){
      return "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1661290121/studio%20visuals/pdfthumbnail_oycrij.png";
    }
    else if(excelFile.includes(imgUrl.split('.').pop())){
      return "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1661290121/studio%20visuals/excelthumbnail_zxgjwn.png";
    }
    else if(videoFile.includes(imgUrl.split('.').pop()) || imgUrl.includes('youtube') || imgUrl.includes('vimeo')){
      return videoIcon;
    }
    else{
      return imgUrl.replace(/\s/g, "%20");
    }
  }
  const getTemplateInfo = showroomTemplates.filter(
    res => res.id == props.templateId
  );

  const { _id } = context.showroomData?.showroom || "";

  const checkRequiredFields = step => {
    const { mainHeading, subHeading, name } = context.showroomDetails;

    let errors = '';

    if (step === 'editor'){
      if (!mainHeading) {
        errors += 'Title, ';
      }
      if (!subHeading) {
        errors += 'SubHeading, ';
      } 
    }
    if(step === 'popup' && !name){
        errors += 'Name, ';
    }
    if (errors !== '') {
      showErrorToast(`${errors} required`);
      return false
    }
    return true
  }

  
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  };
  const grid = 3;

  const getItemStyle = (isDragging, draggableStyle, image) => ({
    // some basic styles to make the items look a bit nicer
    backgroundImage:`url(${image})`,
    backgroundSize: "contain",
    backgroundRepeat:"no-repeat",
    width: "22%",
    userSelect: "none",
    position:"relative",
    display:"flex",
    marginLeft:"10px",
    marginRight:"10px",
    boxSizing: "border-box",
    alignItems:"flex-end",
    justifyContent:"center",
    cursor:"pointer",
    backgroundColor:isDragging ? "#303030":"",
    boxShadow: "0px 3px 20px #000000",
    borderRadius: "5px",
    color: "white",
    aspectRatio:"16/9",
    backgroundSize: "cover",
    // styles we need to apply on draggables
    ...draggableStyle
  });

  const getListStyle = (isDraggingOver, itemsLength) => ({
    background: isDraggingOver ? "transparent" : "transparent",
    display: "flex",
    justifyContent:"center",
    alignItems:"center",
    padding: 2,
    width:"100vw",
    overflowX:"hidden",
    height:"100%",
  });
  const onDragEnd = (result) =>{
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const items = reorder(
      context?.showroomDetails?.content[reorderingRow]?.content,
      result.source.index,
      result.destination.index
    );
    const updatedArr = [...context?.showroomDetails.content];
    const updatedList = { ...updatedArr[reorderingRow], content: items};
    updatedArr.splice(reorderingRow,1,updatedList);
    const contextObj = {
      ...context,
      showroomDetails: {
        ...context.showroomDetails,
        content:updatedArr,
      }
    }
    setContext(contextObj);
    handleShowroomReorder(contextObj);
  }
  const handleShowroomReorder = (contextObj) => {
    const {
      _id: editShowroomId,
      accountId,
      updatedAt,
      templateId,
      createdAt,
      creator,
      content,
      headerImage,
      ...showroom
    } = contextObj.showroomDetails;
    let arrContent = [];
    let innerContent = [];
    for(let i = 0; i< content.length; i++){
      const contentName = content[i].name;
      for(let j = 0; j < content[i].content.length; j++){
       innerContent.push({
        image: content[i].content[j].image,
        mainHeading: content[i].content[j].mainHeading,
        presentationId: content[i].content[j].presentationId,
        subHeading: content[i].content[j].subHeading,
        imageUrl: content[i].content[j].imageUrl,
       })
      }
      arrContent.push({name:contentName, content: innerContent});
      innerContent = [];
    };
    let errors = "";
    if (!showroom.mainHeading) {
      errors += "Title, ";
    }
    if (!showroom.subHeading) {
      errors += "SubHeading,";
    }
    if (errors !== "") {
      return showErrorToast(`${errors} required`);
    }

    EditShowroomMutation(editShowroomId, {...showroom, content: arrContent}, response => {
      responseHandler(response, "Showroom updated successfully")
    });
}


const handleImageClick = (imageUrl) => {
  setContext({
    ...context,
    showroomDetails: {
      ...context.showroomDetails,
      backgroundImage: imageUrl,
    },
  });
};

const hanldleMpublisherImage = (index,item) => {
  setActiveIndex(prevIndex => prevIndex === index ? -1 : index);
  setActiveImage(item.image);
}
//upload and update bgimage
const [openModal, setOpenModal] = useState({open: false});
const [isUploadingThumbnail, setIsUploadingThumbnail] = useState(false);

const updateBgImage = (link) => {
  const { id,portalId,templateId } = context?.showroomDetails;
  let newShowroom = {
    _id: id,
    name: "thumbnail",
    portalId: portalId,
    templateId: templateId,
    backgroundImage: link
  }

  EditShowroomMutation(newShowroom, response => {
    responseHandler(response, "Showroom Updated Successfully", ({_id}) =>{
      setImageChangesQueueBg([])
      setOpenModal({open:false});    
      setIsUploadingThumbnail(false); 
    })
  });
};
const updateThumbnails = async () => {
  setIsUploadingThumbnail(true);
  if(!imageChangesQueueBg.length) {
    return updateBgImage("");
  } else {
       const {uploadedResult : result} = await uploadFilesAndDeleteByUrl(imageChangesQueueBg,"thumbnails")
      if (!result) {
        setIsUploadingThumbnail(false);
      } else{
        const link= `${STATIC_URL}${result[0]?.thumbnailUrl}`;
         updateBgImage(link);
        setContext({
          ...context,
          showroomDetails: {
            ...context.showroomDetails,
            backgroundImage: link,
          },
        });
      setIsUploadingThumbnail(false); 
    }
  }
}
useEffect(() => {
  if(handleBg){
    document.querySelector(".done")?.addEventListener("click", updateThumbnails);
    return () => {
      document.querySelector(".done")?.removeEventListener("click", updateThumbnails);
  }
  }
},[imageChangesQueueBg])

const bgRef = useRef(null); 
  useEffect(() => {
    function handleClickOutside(event) {
      if (bgRef.current  &&  !bgRef.current.contains(event.target)) {
        setBgModal(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  return (
    <>
    {isUploadingThumbnail && <Loader />}
       {preview ? (
        <StickyNav
          unAuthorized={unAuthorized}
          closePreview={onClickPreview}
          noitems
          previewMode
          clickBack={()=>preview ? setPreview(false) : navigate(-1)}
          templateId={context?.showroomDetails?.templateId}
        />
      ) : (
        <StickyNav 
        bgModal={bgModal}
        setBgModal={setBgModal}
        isEditBackground={true}
        templateId={context?.showroomDetails?.templateId}
        />
      )}
       {bgModal && props?.templateId !== 16 &&
       <div ref={bgRef} className="modals-backgrounds">
          <button 
         onClick={() => {
          setHandleBg(true);
          setImageChangesQueueBg([]);
          setOpenModal({
            open: true,
            data: {...context?.showroomDetails,backgroundImage:context?.showroomDetails?.backgroundImage, id: context?.showroomDetails?._id,},
          });
        }}
       >{t("Upload Background")}</button>
       <div className="images-bg">
         <div 
         className={context?.showroomDetails?.backgroundImage?.includes("https://res.cloudinary.com/dsg2ktuqk/image/upload/v1694965985/HD_transparent_picture_lwvng3.png") ? `selected` : `bgHolder`}
         >
          <img
          onClick={()=>handleImageClick("https://res.cloudinary.com/dsg2ktuqk/image/upload/v1694965985/HD_transparent_picture_lwvng3.png")}
           src="https://res.cloudinary.com/dsg2ktuqk/image/upload/v1695394944/icon-transparent_k2fff4.png" alt="bg"/>
         </div>
             {backgroundImages?.map((item,i)=>{
          return(
            <div className={context?.showroomDetails?.backgroundImage?.includes(item) ? `selected` : `bgHolder`}>
            <img

            onClick={() => {handleImageClick(item);
              modals?.tour===true && setCurrentStep(currentStep+1);
            }}
             alt="bg" src={item} key={i} />
             </div>
          )
        })}
       </div>
       <button onClick={() => setMpublisherBackgrounds(true)} className="mpublisherBtn">{t("or choose from")} <span>MPublisher</span></button>
       </div>
       }
       {preview && dimensions?.width>1024 && <div className="presentation-mode-effect">
        <button className={`${presentationMode ? `active-presentation`:`presentation-nonactive`}`}>
          <HiOutlinePresentationChartBar onClick={()=>setPresentationMode(!presentationMode)} />
        </button>
        </div>}
      {!preview ? (
        <>
          <div className={`designtool-template ${presentationMode && `designtool-presenting`}`}>
            <Editor
              handleSaveCoverpage={handleMutation}
              showRoomData={context}
              handleEditCoverpage={handleEditTemplate}
              templateId={context?.showroomDetails?.templateId}
              onClickPreview={onClickPreview}
              showroomId={_id}
              tag={context.tag}
              tags={context?.showroomDetails?.tags}
              handleKeyDown={handleKeyDown}
              handleChangeTag={() => {}}
              handleRemoveTag={handleRemoveTag}
              onChangeMetaData={onChangeMetaData}
              checkRequiredFields={checkRequiredFields}
            />
         
            {context?.showroomDetails?.content&&
             <div className={reorderMode ? "order-showrooms-main" : ""}>
             <div className={`${reorderMode?"visible-show":"order-container"}`} style={{ overflow: "hidden"}}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable" direction="horizontal" ignoreContainerClipping={false}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(
                      snapshot.isDraggingOver,
                      context?.showroomDetails?.content[reorderingRow]?.content?.length
                    )}
                    {...provided.droppableProps}
                  >
                    {context?.showroomDetails?.content[reorderingRow]?.content?.map((item, index) => (
                      <Draggable key={item.presentationId} draggableId={item.presentationId} index={index} >
                        {(provided, snapshot) => (
                          <>
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style,
                              getImage(item?.imageUrl.includes('cloudfront') ? item?.imageUrl : STATIC_URL + item?.imageUrl)
                            )}
                          >
                          </div>
                          
                          </>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <div className="rowitems-container" style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
            {/* {reorderMode &&   <span className="rowspan">Row</span>} */}
            {context?.showroomDetails?.content?.length>1&& reorderMode && context?.showroomDetails?.content?.map((item, index)=>(
              <p className={reorderingRow===index ? `reorder-item activeRowItem` : ` reorder-item`} style={{cursor:"pointer"}} key={index} onClick={()=>setReorderingRow(index)}>{index+1}</p>
            ))}
            </div>
            {reorderMode &&  <div className="reorder-container">
            <p className="reorder-close" style={{color:"gray", cursor:"pointer"}} onClick={()=>setReorderMode(false)}><Close/></p>
            <p className="reorder-text">{t("Drag and drop to sort the order")} </p>
            </div>}
            </div>
            </div>
            }
            {props.showroomData.showroom._id!==undefined?
            <div className={`text-container-button ${reorderMode?"hide-text":""}`}>
              <p style={{color:"gray", cursor:"pointer"}} onClick={()=>{setReorderMode(true)}}><Reorder/></p>
            </div>
            :""}
          
            <DesignEditor
              handleSaveCoverpage={handleMutation}
              onChangeEditor={onChangeEditor}
              onChangeMetaData={onChangeMetaData}
              onClickPreview={() => onClickPreview()}
              handleChangeTag={(e) => setContext({...context, tag: e.target.value})}
              tag={context.tag}
              tags={context?.showroomDetails?.tags}
              handleKeyDown={handleKeyDown}
              handleRemoveTag={handleRemoveTag}
              handleEditCoverpage={handleEditTemplate}
              alreadyExists={location?.state?.alreadyExists}
              imageChangesQueue={imageChangesQueue}
              setImageChangesQueue={setImageChangesQueue}
              checkRequiredFields={checkRequiredFields}
              isLoading={isLoading}
              value={context.showroomDetails.name}
              showPopUp={showPopUp}
              setShowPopUp={setShowPopUp}
              mainHeading={context?.showroomDetails?.mainHeading}
              handleNameInputChange={handleNameInputChange}
              inputValue={nameInputValue}
            >
              <div className={`${(( context?.showroomDetails?.backgroundImage !== undefined) && ( context?.showroomDetails?.backgroundImage !== null) && !(context?.showroomDetails?.backgroundImage?.includes("HD_transparent"))) && openModal.open === false && `preview-templateBlur`} ${props.templateId === 11 && "template-11"}`}>
              <ShowRoom2Template
                isPreview={preview}
                onChangeEditor={onChangeEditor}
                showRoomData={context?.showroomDetails}
                tooltip={true}
                icons={true}
                slideNumber={
                  dimensions?.width<=1024 ? 1 : getTemplateInfo.length > 0 && getTemplateInfo[0].numberOfItems
                }
                itemsClass={
                  parseInt(props.templateId) === 4
                    ? "template-4"
                    : parseInt(props.templateId) === 3 ? "template-3"
                    : parseInt(props.templateId) === 1 || parseInt(props.templateId) === 2 ? "first-templates" 
                    : parseInt(props.templateId) >= 8 && parseInt(props.templateId) <= 11 ? `portrait-template portrait-template-${props.templateId}`
                    : parseInt(props.templateId) >= 12 && parseInt(props.templateId) <= 15 ? `single-template single-template-${props.templateId}`
                    : (parseInt(props.templateId) >= 16 || dimensions?.width<=1024)  ? `vertical-template vertical-template-${props.templateId}` :
                    (dimensions?.width<=1024 && orientation === "landscape") ? `vertical-template vertical-template-${props.templateId} landscape-mobile-template`
                    : "item-center"
                }
                detailsOfTemplate={location?.state?.detailsOfTemplate}
                headerImage={
                  showroomTemplates.filter(
                    item => item.id === parseInt(props.templateId)
                  )[0].headerImage
                }
                isExpandable={
                  props.templateId >= 5 && props.templateId <= 7
                }
                uploadHeader={uploadHeader}
                imageChangesQueue={imageChangesQueue}
                setImageChangesQueue={setImageChangesQueue}
              />
            </div>
            </DesignEditor>
          </div>
        </>
      ) : (
        <div className={`${`designtool-template ${presentationMode && `designtool-presenting`} ${dimensions?.width<=1024 && orientation==="landscape" && `designtool-landscape`}`}`}>
          <div className={`preview-template ${(props?.templateId>=16 || dimensions?.width<=1024) && `vertical-template-preview ${dimensions?.width<=1024 && orientation==="landscape" && `landscape-mobile-template-preview`}`}`}>
          <ShowRoom2Template
            isPreview={preview}
            onChangeEditor={onChangeEditor}
            showRoomData={context?.showroomDetails}
            slideNumber={
              dimensions?.width<=1024 ? 1 : getTemplateInfo.length > 0 && getTemplateInfo[0].numberOfItems
            }
            headerImage={
              showroomTemplates.filter(
                item => item.id === parseInt(props.templateId)
              )[0].headerImage
            }
            isExpandable={props.templateId >= 5 && props.templateId <= 7 ? true : false}
            itemsClass={
              (dimensions?.width<=1024 && orientation === "landscape") ? `vertical-template vertical-template-${props.templateId} landscape-mobile-template`:
              (props.templateId >= 16  || dimensions?.width<=1024 ) ? `vertical-template vertical-template-${props.templateId}` :
              props.templateId === 4
                ? "template-4"
                : props.templateId >= 8 && props.templateId <= 11
                ? `portrait-template portrait-template-${props.templateId}`
                : props.templateId===3 ? "template-3" 
                : props.templateId >= 12 && props.templateId <= 15 ? `single-template single-template-${props.templateId}` :
                 `item-center item-center-${props.templateId}`
            }
          />
          </div>
        </div>
      )}
        {openModal.open && (
        <UploadModal
          closeModal={setOpenModal}
          modal={openModal}
          image={imageChangesQueueBg}
          setImage={setImageChangesQueueBg}
          title="Change Showroom Background"
          microsite={true}
        />
      )}
      {mpublisherBackgrounds &&
        <ImgModal extraClass="mpublisherModal" state={mpublisherBackgrounds} >
          <div className="mpublisherContainer">
            <div>
              <h4 className="title"><MPublisher /> MPublisher</h4>
              <p>Below you find some covers you can use for free from MPublisher, if you want more you need to subscribe</p>
              <div className="mpublisherImages">
                {MPUBLISHER_IMAGES.map((item, i) => (
                  <div key={i} onClick={() => hanldleMpublisherImage(i, item)}
                    className={`image-wrapper ${activeIndex === i ? 'active' : ''}`}>
                    <img src={item.image} alt="mpublisher" />
                  </div>
                ))}
              </div>
            </div>
            <div className="mpublisherButtons">
              <button onClick={() => setMpublisherBackgrounds(false)}>{t("Cancel")}</button>
              <button
                style={!activeImage ? { background: "gray", opacity: "0.2" } : {}}
                onClick={() => {
                  handleImageClick(activeImage);
                  setMpublisherBackgrounds(false);
                }}
              >
                {t("Save")}
              </button>

            </div>
            <p className="seeMore">Want to see more? <a href="https://publisher.masterwizr.com" target="_blank">{t("Explore")} MPublisher</a></p>
          </div>
        </ImgModal>
      }
    </>
  );
}

export default withRouter(ShowRoomDesign);
