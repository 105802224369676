/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SHAPE = "DEFAULT" | "ROUNDED" | "%future added value";
export type THEME = "BLUE" | "BRIGHT" | "DARK" | "GREEN" | "PURPLE" | "%future added value";
export type editAccount = {|
  _id: string,
  name?: ?string,
  creator?: ?string,
  font?: ?string,
  theme?: ?THEME,
  logoUrl?: ?string,
  backgroundImage?: ?string,
  logoShape?: ?SHAPE,
  website?: ?string,
  info?: ?string,
  location?: ?string,
  contact?: ?string,
|};
export type editAccountMutationVariables = {|
  updates?: ?editAccount
|};
export type editAccountMutationResponse = {|
  +editAccount: ?{|
    +__typename: string,
    +message: string,
    +status: number,
    +success: boolean,
  |}
|};
export type editAccountMutation = {|
  variables: editAccountMutationVariables,
  response: editAccountMutationResponse,
|};
*/


/*
mutation editAccountMutation(
  $updates: editAccount
) {
  editAccount(updates: $updates) {
    __typename
    message
    status
    success
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "updates"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "updates",
        "variableName": "updates"
      }
    ],
    "concreteType": "Response",
    "kind": "LinkedField",
    "name": "editAccount",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "editAccountMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "editAccountMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9405c09d634505264e5f4397e09b37b7",
    "id": null,
    "metadata": {},
    "name": "editAccountMutation",
    "operationKind": "mutation",
    "text": "mutation editAccountMutation(\n  $updates: editAccount\n) {\n  editAccount(updates: $updates) {\n    __typename\n    message\n    status\n    success\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f37df2624e9e8c9d9ff76e2cb5fec47a';

module.exports = node;
