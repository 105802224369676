import { useRef, useState } from "react";
import "./FileSlideStyles.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { ReactComponent as ArrowRight } from "assets/images/icon-arrow-right.svg";
import { ReactComponent as ArrowLeft } from "../../../../assets/images/icon-arrow-left.svg";
import File from "components/ui/atoms/Presentations/File";
import useWindowDimensions from "components/ui/atoms/windowDimensions";
import { usePresentationFilesOrdering } from "hooks/useFileHandler";
export default function BottomSlider({ data,presentationId }) {
  const sortedData = 
  usePresentationFilesOrdering(
     presentationId? presentationId : [],
     data?.files || []
   )
  const [activeItem, setActiveItem] = useState(sortedData?.[0]);
  const dimensions = useWindowDimensions();
  const slider = useRef(null);
  const handleItemClick = item => {
    setActiveItem(item);
  };

  const settings = {
    slidesToShow:dimensions?.width<=1024 ? 3 :  data?.files?.length > 5 ? 5 : data?.files?.length,
    slidesToScroll: 1,
    infinite: true,
    arrows: false,
    dots: false,
  };
  return (
    <>
      <div className="main-bottom-slider-container">
        <div className="fileslide-active">
          <File file={activeItem} />
        </div>
        {data?.files?.length > 1 && (
          <Slider ref={slider} {...settings}>
            {sortedData?.map((item, index) => (
              <div
                key={index}
                className={`singlefile ${item === activeItem ? "active" : ""}`}
                onClick={() => handleItemClick(item)}
              >
                <File thumbnail={true} file={item} data={data} index={index} />
              </div>
            ))}
          </Slider>
        )}
      </div>
      {data?.files?.length > 5 && (
        <div className="slider-arrows">
          <button
            className="leftarr"
            onClick={() => slider?.current?.slickPrev()}
          >
            <ArrowLeft />
          </button>
          <button
            className="rightarr"
            onClick={() => slider?.current?.slickNext()}
          >
            <ArrowRight />
          </button>
        </div>
      )}
    </>
  );
}
