import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { FlexContainer } from "../flexContainer";
import { ReactComponent as Add } from "assets/images/add-icon.svg";
import {
  Wrapper,
  Header,
  ItemsWrapper,
  DropdownWrapper,
  AccountsTitle,
  ContentWrapper,
  HeaderWrapper,
  Tile,
  Btn,
  HorizontalRule,
  Footer,
} from "./styles";
import RadioButton from "../../components/ui/atoms/Buttons/RadioButton/RadioButton";
import { IoIosClose } from "react-icons/io";
import { RiBuildingFill } from "react-icons/ri";
import { capitalizeString } from "helpers/capitalizeString";
import { deleteAuthData } from "helpers/authHandler";
import { showSuccessToast } from "@master-wizr/toast";
import coverlogo from "assets/images/coverpage_white.png";
import { ReactComponent as StudioLogoSvg } from "assets/images/studiologo.svg";
import { ReactComponent as MWLogoSvg } from "assets/images/mwlogotop.svg";
import { ReactComponent as Eye } from "assets/images/eye-toggle.svg";
import { ReactComponent as BgIcon } from "assets/images/bgicon.svg";
import { getClientData } from "helpers/queryHandler";
import clientOnly from "graphql/queries/clientOnly";
import { useQuery } from "relay-hooks";
import {
  STATIC_URL,
  ADMINISTRATOR,
} from "constants/env";
import selectData from "graphql/mutations/selectData";
import { setbrowserData, switchActiveProfile } from "helpers/authHandler";
import showroomlogo from "../../assets/images/showroom_white.png";
import BackBtn from "components/ui/molecules/BackBtn/BackBtn";
import styles from "../Templates/Banner/Banner.module.scss";
import { useTour } from "@reactour/tour";
import { useTranslation } from "react-i18next";
import ImgModal from "components/ui/atoms/ImgModal/ImgModal";

const Logo = ({ sharing }) => (
  <FlexContainer ml="10px" vCenterAlign>
    <StudioLogoSvg />
    {!sharing && (
      <Header>
        <b>MW</b>&nbsp;Studio
      </Header>
    )}
  </FlexContainer>
);

export function StickyNav({
                            clickBack,
                            previewMode,
                            closePreview,
                            isMicrosite,
                            handleText,
                            clickEvent,
                            unAuthorized,
                            noCoverpage,
                            micrositeData,
                            createMode,
                            showroomIds,
                            bgModal,
                            setBgModal,
                            isEditBackground,
                            magazineClick,
                            templateId,
                          }) {
  const { t } = useTranslation();
  const profile = getClientData(useQuery, clientOnly, {}, "Profile");
  const profiles = getClientData(useQuery, clientOnly, {}, "Accounts" || []);
  const [coverSrc, setCoverSrc] = useState(coverlogo);
  const [infoModal, setInfoModal] = useState(false);
  const [showroomSrc, setShowroomSrc] = useState(showroomlogo);
  const personal = getClientData(useQuery, clientOnly, {}, "Profile");
  const user = { ...personal, ...personal?.userInfo };
  const [showAddModal, setShowAddModal] = useState(false);

  const switchAccount = portal => {
    const portalId = `${portal?.accountId}-${portal?.accessedPortals[0]?.portalId}`;
    let portal_id = portalId.split("-")[1];
    const array_portals = Object.values(portal?.accessedPortals);
    const result = array_portals.find(item => item?.portalId === portal_id);
    selectData(result, "Portal");
    switchActiveProfile(true, result.portalId, "portalId");
  };
  const handleLogout = () => deleteAuthData(false);
  const handleSelect = company => {
    selectData({ ...company, userInfo: personal.userInfo }, "Profile", false);
    const accountId = company.accountId || company.accountInfo?._id;
    if (accountId) {
      setbrowserData(`${accountId}-noPortalId`, "activeProfile");
    }
    switchAccount(company);
    showSuccessToast(
      `You have switched account to ${capitalizeString(
        company?.accountInfo?.name,
      )}`,
    );
  };

  const userInitials = profile?.userInfo?.firstName
    ?.split(" ")
    .map(n => n[0])
    .join("");
  const lastInitials = profile?.userInfo?.lastName
    ?.split(" ")
    .map(n => n[0])
    .join("");
  const navigation = useNavigate();
  const isSharingMode = window.location.pathname.includes("/share/");
  const [noCover, setNoCover] = useState(false);
  useEffect(() => {
    setNoCover(noCoverpage);
  }, [noCoverpage]);
  const { id } = useParams();
  const links = [
    {
      title: "Create",
      to: window.location?.pathname?.includes("/showroom")
        ? `/showroom-templates`
        : `/coverpage-templates`,
      accountRole: ADMINISTRATOR,
    },
    {
      title: "Present",
      to: window.location?.pathname?.includes("/showroom")
        ? `/showrooms`
        : `/cover-pages`,
    },
  ];
  const { currentStep, setCurrentStep } = useTour();
  const modals = getClientData(useQuery, clientOnly, {}, "Modal");
  return (
    <>
      {(window.location.pathname?.includes("/showroom-templates") ||
          window.location.pathname?.includes("/coverpage-templates") ||
          window.location.pathname?.includes("/cover-pages") ||
          window.location.pathname?.includes("/showrooms")) &&
        !window.location.pathname?.includes(`/showrooms/${id}`) && (
          <div className={styles.tabs}>
            {links.map(link => {
              return (
                <Link
                  className={
                    window?.location?.pathname.includes(link.to)
                      ? styles.selected
                      : ``
                  }
                  key={link.id}
                  to={link.to}
                >
                  {t(link.title)}
                </Link>
              );
            })}
          </div>
        )}
      {isSharingMode && !isMicrosite && (
        <div className="logo-sharing">
          <Logo sharing={true} />
        </div>
      )}
      {isSharingMode && (
        <div className="powered-sharing">
          <p>Powered by </p> {" "}
          <button onClick={() => setInfoModal(true)} >
             MASTER WiZR
          </button>
        </div>
       )} 
      {!previewMode && isEditBackground && templateId !== 16 && (
        <div
          className={`showroom-bg-toggle ${
            isMicrosite && `bgtoggle-microsite`
          }`}
        >
          <button
            onClick={() => {
              setBgModal(!bgModal);
              modals?.tour && setCurrentStep(currentStep + 1);
            }}
          >
            <BgIcon />
          </button>
        </div>
      )}
      <Wrapper
        className={`${
          previewMode
            ? `preview-navigation`
            : isMicrosite && `microsite-navigation`
        } main-nav-templates`}
      >
        {!isSharingMode &&
          !previewMode &&
          !window.location?.pathname.includes("/studio-guide") &&
          !window.location?.pathname
            .split("/")
            [window.location?.pathname.split("/").length - 1].includes(
            "magazine",
          ) && (
            <BackBtn
              onClick={(isMicrosite || previewMode) && clickBack}
              magazineClick={magazineClick}
              isPreview={previewMode}
              isMicrosite={isMicrosite}
            />
          )}
        {!isSharingMode && !isMicrosite && !previewMode && (
          <Link to={"/"}>
            <Logo />
          </Link>
        )}
        {previewMode && unAuthorized === false && !isSharingMode && (
          <div
            onClick={() => {
              closePreview();
              modals?.tour && setCurrentStep(currentStep + 1);
            }}
            className="eye-preview"
          >
            <Eye />
            <p>Go to Edit Mode</p>
          </div>
        )}
        <ItemsWrapper width="60%" height="100%">
          {/* <Templates  /> */}
          {isMicrosite === true ? (
            noCover === false ? (
              <div className="nav-microsite">
                <div className="container-microsite-nav">
                  {handleText?.includes("Show") ? (
                    <img
                      src={coverSrc}
                      onClick={clickEvent}
                      onMouseOver={() => setCoverSrc(showroomlogo)}
                      onMouseOut={() => setCoverSrc(coverlogo)}
                      alt=""
                    />
                  ) : (
                    <img
                      src={showroomSrc}
                      onClick={clickEvent}
                      onMouseOver={() => setShowroomSrc(coverlogo)}
                      onMouseOut={() => setShowroomSrc(showroomlogo)}
                      alt=""
                    />
                  )}
                  <button>{handleText}</button>
                </div>
              </div>
            ) : (
              <div className="nav-microsite">
                <div className="container-microsite-nav">
                  {micrositeData === null && !createMode ? (
                    <p>No Cover Page Selected</p>
                  ) : (
                    <p>
                      No Cover Page Selected{" "}
                      {!unAuthorized && (
                        <Add
                          onClick={
                            !createMode
                              ? () =>
                                navigation(
                                  `/edit-coverpage-microsite/${micrositeData[0].toString()}/2/${
                                    micrositeData[1]
                                  }/null/null`,
                                )
                              : () =>
                                navigation(
                                  `/cover-pages/${showroomIds}/2/null/null`,
                                )
                          }
                        />
                      )}
                    </p>
                  )}
                </div>
              </div>
            )
          ) : (
            <div
              id="possible-nav"
              style={{
                height: "100%",
                position: "relative",
              }}
            ></div>
          )}
        </ItemsWrapper>
        {!isMicrosite && !previewMode && <MWLogoSvg className="logosvg" />}
      </Wrapper>
      {!isMicrosite &&
        !previewMode &&
        !window.location.pathname?.includes(`/showrooms/${id}`) &&
        !window.location.pathname?.includes(`/coverpage-detailed/${id}`) && (
          <div
            onClick={() => setShowAddModal(!showAddModal)}
            className="initials-top"
          >
            {user?.profilePhoto ? (
              <img src={`${STATIC_URL}${user?.profilePhoto}`} alt=""/>
            ) : (
              <>
                {userInitials}
                {lastInitials}
              </>
            )}
          </div>
        )}
      {showAddModal && (
        <>
          <DropdownWrapper columns className="dropdown-manage-accounts">
            <AccountsTitle spaceBetween mb="10px" className="accounts-title">
              <FlexContainer vCenter>
                <HeaderWrapper vCenter hCenter mr="10px" className="manage-svg">
                  <RiBuildingFill size="22" />
                </HeaderWrapper>
                <FlexContainer columns>
                  <h3>{t("Manage accounts")}</h3>
                  <p>{t("switch-manage-accounts")}</p>
                </FlexContainer>
              </FlexContainer>
              <FlexContainer
                className="close"
                vCenter
                hCenter
                onClick={() => setShowAddModal(!showAddModal)}
                pointer
              >
                <IoIosClose size="25" />
              </FlexContainer>
            </AccountsTitle>
            <ContentWrapper width="100%">
              <FlexContainer width="100%">
                {profiles.map(company => (
                  <Tile
                    pd="0 10px"
                    key={company.accountId}
                    onClick={() => {
                      handleSelect(company);
                    }}
                    className="account-name"
                  >
                    <RadioButton
                      reverse
                      option={{
                        value:
                          company?.accountInfo?.name.length > 30
                            ? `${company?.accountInfo?.name.substring(
                              0,
                              30,
                            )} . . .`
                            : company?.accountInfo?.name,
                      }}
                      checked={user?.accountInfo?.name}
                    />
                  </Tile>
                ))}
              </FlexContainer>
              <Footer columns width="100%" vCenter mb="20px">
                <HorizontalRule mt="30px" mb="10px" />
                <Btn
                  className="secBtn"
                  sm
                  mt="8px"
                  width="100%"
                  onClick={handleLogout}
                >
                  {t("Logout")}
                </Btn>
              </Footer>
            </ContentWrapper>
          </DropdownWrapper>
        </>
      )}
      {infoModal &&
      <>
      <button className={styles.backbutton} onClick={() => setInfoModal(false)}>Go Back</button>
      <ImgModal extraClass="infomodal" state={infoModal} >
      <div className={styles.sharingInfo}>
        <div>
        <h1 className={styles.infoTitle}>Welcome to Studio - <br/> Check out empowering and creative ways to connect</h1>
        <p className={styles.desc}>Discover new ways to bundle material for situations that matters to you:</p>
        <ul className={styles.infoList}>
          <li>Proposals in sales</li>
          <li>Project descriptions</li>
          <li>Investor pitches</li>
          <li>Campaigns</li>
          <li>Job applications</li>
          <li>All type of onboarding</li>
        </ul>
        <p className={styles.subtitle}>Take it for a <button onClick={() => window.open("https://studio.masterwizr.com")} target="_blank"> free spin</button></p>
        <button onClick={() => window.open("https://studio.masterwizr.com")} target="_blank" className={styles.studioBtn}>Go to Studio</button>
        </div>
        <img src="https://res.cloudinary.com/dsg2ktuqk/image/upload/v1715089635/Templates/WhatsApp_Image_2024-04-19_at_18.21_1_avyaea.png" />
      </div>
      </ImgModal>
      </>
      }
    </>
  );
}

export default StickyNav;
