import React from "react";
import styles from "./Card.module.scss";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Card({ image, title, ...rest }) {
  const navigate = useNavigate();
  const {t} = useTranslation()

  return (
    <div className={styles.card}>
      <img src={image} alt="card" className={styles.image} />
      <h3 className={styles.title}>{t(title)}</h3>
      <p className={styles.desc}>{t(rest?.desc)}</p>
      <button
        onClick={() => navigate(`/${rest?.toLink}`)}
        className={styles.btn}
      >
        {t(rest?.btnText)}
      </button>
    </div>
  );
}

Card.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  desc: PropTypes.string,
  btnText: PropTypes.string,
};
