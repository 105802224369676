import { showErrorToast } from "@master-wizr/toast";
import { extractInnerHtmlText } from "./extractInnerHtmlText";
import responseHandler from "./responseHandler";

export const checkCharLimit = (ed, limit) => {
  const allowedKeys = [8, 37, 38, 39, 40, 46];
  ed.on("init", function (e) {
    const elements = document.getElementsByClassName("mce-content-body");
    const requiredElement = elements[0];
    eventFire(requiredElement, "click");
    requiredElement.click();
  });
  ed.on("paste", function (e) {});
  ed.on("keydown", function (e) {

    if (allowedKeys.indexOf(e.keyCode) !== -1) return true;
    const div = document.createElement("div");
    div.innerHTML = ed.getContent();
    if (div.textContent.length + 1 > limit) {
      e.preventDefault();
      e.stopPropagation();
      const response = { message: "Reached character limit" };
      responseHandler(response);
      return false;
    }
    return true;
  });

  ed.on(
    "PastePreProcess",
    function (e) {
      if (
        this &&
        this.settings &&
        extractInnerHtmlText(e.content).length > this.settings.max_chars
      ) {
        e.preventDefault();
        e.stopPropagation();
        showErrorToast(
          "You have pasted text with characters more than the required text."
        );
        e.content = "";
      } else {
        return e.content = e.content;
      }
    }.bind(ed)
  );
};

const eventFire = (el, etype) => {
  if (el.fireEvent) {
    el.fireEvent("on" + etype);
  } else {
    var evObj = document.createEvent("Events");
    evObj.initEvent(etype, true, false);
    el.dispatchEvent(evObj);
  }
};

export const returnEditorFontSize = field => {
  if (["headingInit"].includes(field)) {
    return "28px";
  }
  if (["subHeadingInit"].includes(field)) {
    return "18px";
  }
  if (["subBodyInit"].includes(field)) {
    return "17px";
  }
  return "8px 10px 12px 14px 18px 24px 36px 38px 40px 45px 50px 60px";
};
