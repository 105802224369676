export const thumbnailGenerator = (type, url) => {
    switch (type) {
        case "video":
          return "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1709316972/filesthumb/videothumb_genqsx.jpg";
        
        case  "links":
          return "links";
  
        case "image":
          return url||"image";
  
        case "word":
          return "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1709290300/filesthumb/wordthumb_ktgxyp.jpg";
  
        case "spreadsheet":
          return "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1709290300/filesthumb/excelthumb_eclao5.jpg";
  
        case "powerpoint":
          return "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1661290121/studio%20visuals/pptthumbnail_jqrrk9.png";
  
        case "pdf":
          return "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1709290300/filesthumb/pdfthumb_dd1uj1.jpg";
        
        default:
          return type;
    }
};