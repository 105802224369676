import React, { useState, useEffect } from "react";
import PreviewNavBar from "../../../../containers/NavBar/preview";
import StickyNav from "containers/NavBar";
import DeleteModal from "containers/Modals/deleteModal";
import SavingModal from "containers/Modals/SavingModal";
import DesignEditor from "../../atoms/DesignEditor/DesignEditor";
import PopUp from "components/ui/atoms/PopUp/PopUp";
import Editor from "../../atoms/Editor/Editor";
import "./CoverpageDetailedPage.scss";
import { image, overlay } from "../DesignLeft";
import CreateCoverpageMutation from "graphql/mutations/CreateCoverpageMutation";
import EditCoverpageMutation from "graphql/mutations/EditCoverpageMutation";
import DeleteCoverpageMutation from "graphql/mutations/DeleteCoverpageMutation";
import { showErrorToast, showSuccessToast } from "@master-wizr/toast";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SharedText from "components/ui/atoms/SharedText/SharedText";
import {
  mainHeadingInit,
  mainSubHeadingInit,
} from "components/ui/atoms/CoverPages/editorSettings";
import { useQuery } from "relay-hooks";
import bg from "../../../../assets/images/bg.png";
import nobg from "../../../../assets/images/nobg.png";

import {
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { getAccountId ,getClientData } from "helpers/queryHandler";
import clientOnly from "graphql/queries/clientOnly";
import { SUPER_ROLES, PORTAL_ROLES, STATIC_URL } from "constants/env";
import coverbg2 from "assets/images/cover2t.jpeg";
import coverbg1 from "assets/images/cover1t.jpeg";
import coverbg3 from "assets/images/cover8t.jpeg";
import coverbg4 from "assets/images/cover4t.jpeg";
import coverbg5 from "assets/images/cover13t.jpeg";
import coverbg6 from "assets/images/cover14t.jpg";
import coverbg7 from "assets/images/cover12t.jpeg";
import covernew1 from "assets/images/covernew1.jpg";
import covernew2 from "assets/images/covernew2.jpg";
import covernew3 from "assets/images/covernew3.jpg";
import covernew4 from "assets/images/covernew4.jpeg";
import {ReactComponent as DeleteIcon} from "assets/images/circular-trash.svg";
import {ReactComponent as EditIcon} from "assets/images/edit.svg";
import UploadModal from "containers/Modals/UploadModal";
import {deleteFilesByUrls, uploadFilesAndDeleteByUrl } from "helpers/fileUpload";
import responseHandler from "helpers/responseHandler";
import { fileUploadPrefixes } from "constants/others";
import { createPortal } from "react-dom";
import FakeNav from "components/ui/molecules/FakeNav/FakeNav";


export default function CoverpageDetailedPage({
  data,
  id,
  isMicrosite,
  setShowCoverPage,
  deleteMicrositeFunc,
  editCoverpageSelection,
  showroomsIdsLength,
  deleteMicrosite,
  createMode,
  showCoverPage,
  isEdit
}) {
  const [backgroundUrl, setBackgroundUrl] = useState(
    "https://master-wizr-uploads.s3.amazonaws.com/studio/images.png"
  );
  const stylesMapper = {
    1: { template: "firstTemplate", image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1703701015/studio%20visuals/Frame_20496_gwc2et.jpg" },
    2: { template: "secondTemplate", image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1703701000/studio%20visuals/Frame_20497_vdwdlz.jpg" },
    3: { template: "thirdTemplate", image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1703701000/studio%20visuals/Frame_20498_o9onka.jpg" },
    4: { template: "fourthTemplate", image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1703701000/studio%20visuals/Frame_20499_xasmzz.jpg" },
    5: { template: "fifthTemplate", image:  "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1703701000/studio%20visuals/Frame_20500_dljnrn.jpg"},
    6: { template: "sixthTemplate", image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1703701000/studio%20visuals/Frame_20501_anlzky.jpg" },
    7: { template: "seventhTemplate", image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1703701000/studio%20visuals/Frame_20502_rpkbbe.jpg" },
  };
  const [textColor, setTextColor] = useState("#FFFFFF");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRemoveCovModal, setShowRemoveCovModal] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [edited, setEdited] = useState(true);
  const [isPreview, setIsPreview] = useState(false);
  const [isCheckoutShowrooms, setIsCheckoutShowrooms] = useState(false);
  const [deletePopup,setDeletePopup] = useState(false);
  const [cardPosition, setCardPosition] = useState(data && data.coverpage && data.coverpage.templateStyle?.cardPosition? data.coverpage.templateStyle?.cardPosition:    stylesMapper[id].template === "fifthTemplate" ? "cardPositionFlexStart" : "cardPositionFlexEnd");
  const [backgroundBlur, setBackgroundBlur] = useState(data && data.coverpage && data.coverpage.templateStyle?.BackgroundBlur? data.coverpage.templateStyle?.BackgroundBlur:"backgroundBlur40");
  const portal = getClientData(useQuery, clientOnly, {}, "Portal");

  const handleKeyDown = e => {
    let val = e.target.value;

    if (e.key === "Enter" && val.length > 1 && !coverpage.tags.includes(val)) {
      setCoverPage(prevState => ({
        ...prevState,
        tags: [...prevState.tags, val],
      }));
      e.target.value = "";
    } else if (e.key === "Enter" && val.length > 1 && coverpage.tags.includes(val)) {
      return showErrorToast(`Tag already exists`);
    }
  };

  const handleRemoveTag = i => {
    const updatedTags = coverpage.tags.filter(tag => tag !== i);
    setCoverPage(prevState => ({
      ...prevState,
      tags: updatedTags,
    }));
  };

  const portalRole = getClientData(
    useQuery,
    clientOnly,
    {},
    "Portal"
  )?.portalRole;
  const accountRole = getClientData(
    useQuery,
    clientOnly,
    {},
    "Profile"
  )?.accountRole;
  const [unAuthorized, setUnAuthorized] = useState(false);

  const hideDeletePopup = () => {
    setDeletePopup(false);
  }
  const [coverpage, setCoverPage] = useState(
    data?.coverpage
      ? data.coverpage
      : {
        name: "",
        mainHeading: ``,
        tags: [],
        details: ``,
          showroomLink: `Checkout Showrooms`,
          accountId: getAccountId(),
          templateId: 2,
          showroomIds: [],
        }
  );
  const [openModal, setOpenModal] = useState({open: false});
  const [imageChangesQueue, setImageChangesQueue] = useState([]);
  const [isUploadingCoverpageThumbNail, setIsUploadingCoverpageThumbNail] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isDeletingBackgroundLoading, setDeletingBackgroundLoading] = useState(false);

  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (searchParams.get("id") && data) {
      setCoverPage(data.coverpage);
      if (data.coverpage?.templateStyle?.cardPosition) {
        setCardPosition(data.coverpage.templateStyle.cardPosition);
      }
      if (data.coverpage?.templateStyle?.BackgroundBlur) {
        setBackgroundBlur(data.coverpage.templateStyle.BackgroundBlur);
      }
    }
  }, [data]);

  useEffect(() => {
    if (
      !(PORTAL_ROLES.includes(portalRole) || SUPER_ROLES.includes(accountRole))||portalRole==="PUBLISHER"
    ) {
      setUnAuthorized(true);
      setIsPreview(true);
    }
    let urlTempId = null;
    if (isMicrosite) {
      setIsPreview(true);
      urlTempId = Number(window.location.pathname.split("/")[3]);
    } else {
      urlTempId = Number(window.location.pathname.split("/")[2]);
    }
    const newObj = {
      ...coverpage,
      templateId: urlTempId,
    };
    setCoverPage(newObj);
  }, []);

  const onChangeEditor = editorValue => content => {
    switch (editorValue) {
      case "name":
        if (coverpage.name !== content.target.value) {
          let coverpageEdited = { ...coverpage, name: content.target.value };
          setCoverPage(coverpageEdited);
        }
        break;
      case "mainHeading":
        if (coverpage.mainHeading !== content) {
          let coverpageEdited = { ...coverpage, mainHeading: content };
          setCoverPage(coverpageEdited);
        }
        break;
      case "showroomLink":
        if (coverpage.showroomLink !== content) {
          let coverpageEdited = { ...coverpage, showroomLink: content };
          setCoverPage(coverpageEdited);
        }
        break; 
      case "subHeading":
        if (coverpage.details !== content) {
          let coverpageEdited = { ...coverpage, details: content };
          setCoverPage(coverpageEdited);
        }
        break;

      default:
        break;
    }
  };

  const changeBackgroudUrl = (backgroundUrl, textColor) => {
    setBackgroundUrl(backgroundUrl);
    setTextColor(textColor);
  };

  const toggleDeleteModal = toggle => {
    setShowDeleteModal(toggle);
  };

  const toggleRemoveCovModal = toggle => {
    setShowRemoveCovModal(toggle);
  };

  const onClickPreview = () => {
    setIsPreview(true);
    const editorList = document.querySelectorAll(".tox-editor-container");
    editorList.forEach((editor, _) => {
      if (!editor.classList.contains("editor-hide")) {
        editor.style.display = "none";
      }
    });
  };

  const navigateBack = () => {
    // console.log('implement navigation')
  };

  const closePreview = () => {
    setIsPreview(false);
    const editorList = document.querySelectorAll(".tox-editor-container");

    editorList.forEach((editor, _) => {
      if (!editor.classList.contains("editor-hide")) {
        editor.style.display = "flex";
      }
    });
  };
  const handleEditCoverpage = async (link = "") => {
    const {
      _id: editCoverpageId,
      id,
      __typename,
      updatedAt,
      templateId,
      createdAt,
      creator,
      ...edited
    } = coverpage;
      edited.templateStyle = {
        cardPosition: cardPosition,
        BackgroundBlur: backgroundBlur,
      };
    let errors = "";
    if (!coverpage.mainHeading) {
      errors += "Title, ";
    }
    if (!coverpage.showroomLink) {
      errors += "showroomlink, ";
    }
    if (!coverpage.details) {
      errors += "SubHeading,";
    }
    if (errors !== "") {
      return showErrorToast(`${errors} required`);
    }

    if (link) edited.thumbnail = link;
    EditCoverpageMutation(editCoverpageId, edited, response => {
      responseHandler(response, "Coverpage Edit Successfully", () =>{
        navigate("/cover-pages");
      })
    });
    setEdited(false);
  };


  const handleSaveCoverpage = (link = "") => {
    if (!portal) {
      return toast.error("Select portal", {
        theme: "dark",
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    let newCoverPage = {...coverpage, portalId: portal.portalId }
      newCoverPage.templateStyle = {
        cardPosition: cardPosition,
        BackgroundBlur: backgroundBlur,
      };
    let errors = "";
    if (!newCoverPage.mainHeading) {
      errors += "Title, ";
    }
    if (!newCoverPage.showroomLink) {
      errors += "showroomlink, ";
    }
    if (!newCoverPage.details) {
      errors += "SubHeading,";
    }
    if (!newCoverPage.name) {
      errors += "Name";
    }
    if (errors !== "") {
      return showErrorToast(`${errors} required`);
    }
    setIsLoading(true)
    newCoverPage.thumbnail = link
    CreateCoverpageMutation(newCoverPage, response => {
      responseHandler(response, "Coverpage Created Successfully", () =>{
        setCoverPage(coverpage);
        navigate("/cover-pages");
      })
      setIsLoading(false)
      setEdited(false);
    });
  };

  //   handleSave = () => {
  //     // Implentation to add login for saving content.
  //     this.navigateBack();
  //   }

  const deleteCoverpage = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const { id } = params;

    if (id) {
      DeleteCoverpageMutation(id, response => {
        toggleDeleteModal();
        responseHandler(response, "Coverpage Deleted Successfully", () =>{
          navigate("/cover-pages");
        })
      });
    }
  };

  const closeCheckoutShowrooms = () => {
    setIsCheckoutShowrooms(false);
  };


  const checkRequiredFields = (step) => {
    let errors = "";
    if(step === 'editor'){
      if (!coverpage.mainHeading) {
        errors += "Title, ";
      }
      if (!coverpage.showroomLink) {
        errors += "showroomlink, ";
      }
      if (!coverpage.details) {
        errors += "SubHeading,";
      }
    }
    if(step === 'popup' && !coverpage.name){
      errors += "Name";
    }
    if (errors !== "") {
      showErrorToast(`${errors} required`);
      return false;
    }
    return true;
  }

 const updateThumnails = async () => {
    let link = "";
    if(imageChangesQueue.length === 0) {
      setImageChangesQueue([])
    }
    else{
      setIsLoading(true);
      const {uploadedResult : result} = await uploadFilesAndDeleteByUrl(imageChangesQueue, fileUploadPrefixes.coverpages)
      setIsLoading(false);
      if(!result || result.length === 0) return;
      setIsUploadingCoverpageThumbNail(false)
      link= result[0].fileUrl;
      setImageChangesQueue([])
      return openModal.data.id === "create" ? handleSaveCoverpage(link): handleEditCoverpage(link)
    }
  }

  const coverpageBackgroundDeleter = async (thumbnail,  modalData ) => {
    setDeletingBackgroundLoading(true)

    // TODO delete the current one
    await deleteFilesByUrls([thumbnail])
    EditCoverpageMutation(modalData.data.id, {thumbnail: ""}, response => {
      if (response.editCoverpage) {
        const {
          editCoverpage: { _id, message },
        } = response;
        if (_id) {
          showSuccessToast("Coverpage Edit Successfully");
        } else {
          showErrorToast(message);
        }
      } else {
        if (response?.message?.includes("Server Error:")) {
          showErrorToast(response.message.split(":")[1]);
        } else {
          showErrorToast(response.message);
        }
      }

      setOpenModal(false)
      setDeletingBackgroundLoading(false)
    });
  }



  const handleSetImageChangesQueue = (data) => {
    setImageChangesQueue(data);
    setIsUploadingCoverpageThumbNail(true);
  }

  const isSharingMode = window.location.pathname.includes("/share/");

  return (
    <div className="coverpage-container">
      {isPreview ? (
        !isMicrosite ? (
          <StickyNav
            unAuthorized={unAuthorized}
            closePreview={closePreview}
            noitems
            previewMode
            clickBack={closePreview}
          />
        ) : (
          <>
            <StickyNav
              clickEvent={() => setShowCoverPage(false)}
              isMicrosite
              noitems
              noCoverpage={false}
              handleText={"Open Show Room"}
              showCoverPage={showCoverPage}
              clickBack={() => navigate(-1)}
            />
            {isEdit && (
              <>
                {!unAuthorized && (
                  <div
                    className="coverpage-microsite-icons"
                    style={{
                      display: "flex",
                      marginTop: "10px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <EditIcon onClick={() => editCoverpageSelection()} />
                    {showroomsIdsLength > 1 ? (
                      <DeleteIcon onClick={() => toggleRemoveCovModal(true)} />
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </>
            )}
          </>
        )
      ) : (
        <>
          <StickyNav />
          {!isPreview && (() => {
            const theNav = document.getElementById("possible-nav");
            if (theNav) {
              return createPortal(
                <FakeNav />,
                document.querySelector(".main-nav-templates")
              );
            }
            return null;
          })()}
          <Editor
            toggleModal={toggleDeleteModal}
            navigateBack={navigateBack}
            onClickPreview={onClickPreview}
            isPreview={isPreview}
            coverpageId={coverpage?.id}
            handleEditCoverpage={handleEditCoverpage}
          />
        </>
      )}
      <DesignEditor
        extraClassName="detailed-part"
        title={"Cover Page"}
        tags={coverpage.tags}
        handleKeyDown={handleKeyDown}
        handleRemoveTag={handleRemoveTag}
        image={image}
        overlay={overlay}
        coverpageId={coverpage?.id}
        changeBackgroudUrl={changeBackgroudUrl}
        handleSaveCoverpage={
          isUploadingCoverpageThumbNail ? updateThumnails : handleSaveCoverpage
        }
        handleEditCoverpage={
          isUploadingCoverpageThumbNail ? updateThumnails : handleEditCoverpage
        }
        onChangeMetaData={onChangeEditor}
        isPreview={isPreview}
        isCheckoutShowrooms={isCheckoutShowrooms}
        onClickPreview={onClickPreview}
        closeCheckoutShowrooms={closeCheckoutShowrooms}
        alreadyExists={location.search}
        onChangeEditor={onChangeEditor}
        checkRequiredFields={checkRequiredFields}
        isLoading={isLoading}
        value={coverpage.name}
      >
        <div id={(data && data.coverpage && data.coverpage.id) || "create"}>
          <div
            style={{
              backgroundImage: `url(${
                data?.coverpage?.thumbnail
                  ? `${STATIC_URL}${data.coverpage.thumbnail}`
                  : stylesMapper[id].image
              })`,
            }}
            className={isMicrosite ? "wrapper microsite-mode" : "wrapper"}
          >
            {!isPreview && !isSharingMode && !unAuthorized && !isMicrosite && (
              <div
                className="plus-icon"
                onClick={() => {
                  setImageChangesQueue([]);
                  if (data?.coverpage?.id)
                    setOpenModal({ open: true, data: { ...data.coverpage } });
                  else {
                    setOpenModal({ open: true, data: { id: "create" } });
                  }
                }}
              >
                +
              </div>
            )}
            <div
              className={`${stylesMapper[id].template} ${
               cardPosition
              }`}
            >
              <div className="testWrapper">
                <div className={`templateWrapper ${backgroundBlur}`}>
                  <div className="templateWrapperText">
                    <SharedText
                      isPreview={isPreview}
                      title={coverpage.mainHeading}
                      isSubHead={true}
                      description={coverpage.details}
                      onChangeEditor={onChangeEditor}
                      mainHeadingInit={mainHeadingInit}
                      subHeadingInit={mainSubHeadingInit}
                      disabled={isPreview}
                      isShowroomLink={true}
                      redirectEvent={() => isPreview && setShowCoverPage(false)}
                      showroomLink={coverpage.showroomLink}
                    />
                  </div>
                </div>
              </div>
            </div>
          {!isPreview && !unAuthorized && (
            <div className="cover-edit-bottom">

                      <div className="buttonsPosition">
                        <div className="text-position">
                          <div
                            className={`color ${cardPosition === "cardPositionFlexStart" && "activecovertext"}`}
                            onClick={() =>
                              setCardPosition("cardPositionFlexStart")
                            }
                          ></div>
                          <div
                            className={`color ${cardPosition === "cardPositionCenter" && "activecovertext"}`}
                            onClick={() =>
                              setCardPosition("cardPositionCenter")
                            }
                          ></div>
                          <div
                            className={`color ${cardPosition === "cardPositionFlexEnd" && "activecovertext"}`}
                            onClick={() =>
                              setCardPosition("cardPositionFlexEnd")
                            }
                          ></div>
                        </div>
                      </div>
                      <div className="more-buttons">
                        <img
                          src={bg}
                          alt="bg"
                          onClick={() =>
                            setBackgroundBlur(prevState => {
                              prevState === "backgroundBlur0"
                                ? setBackgroundBlur("backgroundBlur40")
                                : setBackgroundBlur("backgroundBlur0");
                            })
                          }
                        />
                        <div
                          className={`white-icon ${backgroundBlur === "backgroundBlur20" && "activecoverbg"}`}
                          onClick={() => setBackgroundBlur("backgroundBlur20")}
                        ></div>
                        <div
                          className={`black-icon ${backgroundBlur === "backgroundBlur40" && "activecoverbg"}`}
                          onClick={() => setBackgroundBlur("backgroundBlur40")}
                        ></div>
                      </div>
                      </div>
          )}
          </div>
        </div>
      </DesignEditor>
      {openModal.open && (
        <UploadModal
          closeModal={setOpenModal}
          modal={openModal}
          loading={isDeletingBackgroundLoading}
          image={imageChangesQueue}
          thumbnailDeleter={coverpageBackgroundDeleter}
          setImage={handleSetImageChangesQueue}
          title="Change coverpage background"
        />
      )}
      {showDeleteModal && (
        <DeleteModal
          text={"Are you sure you want to delete this coverpage?"}
          toggleModal={toggleDeleteModal}
          deleteFunc={deleteCoverpage}
        />
      )}
      {showRemoveCovModal && (
        <DeleteModal
          text={
            "Are you sure you want to remove the coverpage from the microsite?"
          }
          toggleModal={toggleRemoveCovModal}
          deleteFunc={deleteMicrositeFunc}
        />
      )}
      {showSaveModal && (
        <SavingModal
        //   handleSave={handleSave}
        />
      )}
      <PopUp
        state={deletePopup}
        hide={hideDeletePopup}
        width="100%"
        maxWidth="800px"
      >
        <div className="center-content">
          <p className="title">Delete Microsite</p>
          <p className="title">
            Are you sure you want to delete the entire microsite?
          </p>

          <div className="buttons-popup">
            <button onClick={hideDeletePopup} className="finish">
              Cancel
            </button>
            <button
              onClick={() => {
                deleteMicrosite();
              }}
              className="finish"
            >
              Done
            </button>
          </div>
        </div>
      </PopUp>
    </div>
  );
}
