import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UniversalMenu } from "@master-wizr/universal-menu";
import {
  deleteAuthData,
  switchActiveProfile,
} from "helpers/authHandler";
import { getAccountId, getClientData } from "helpers/queryHandler";
import { useQuery } from "relay-hooks";
import clientOnly from "graphql/queries/clientOnly";
import selectData from "graphql/mutations/selectData";
import {
  STUDIO_URL,
  LIBRARY_URL,
  LOGIN_URL,
  MPUBLISHER_URL,
  WIZRX_URL,
  VIDEO_DASHBOARD_URL,
  WIZRUP_URL,
} from "constants/env";
import { showErrorToast, showSuccessToast } from "@master-wizr/toast";
import { useMutation } from "react-relay";
import editAccount from "graphql/mutations/editAccount";
import Cookies from "universal-cookie";
import { useHasModuleAccess } from "hooks/useModuleAccess";

const initialValues = {
  CURRENT_ORG: "engineering",
  mode: "dark",
  displayValue: "-100",
  accounts: [
    "Intranet",
    "Sales",
    "HR",
    "Training",
    "Community",
    "Manuals",
    "Management",
    "Marketing",
    "Investors",
  ],
};
export default function UniversalMenuComponent({ showMenu, handleShowMenu }) {
  const [values, setProps] = useState(initialValues);
  const navigate = useNavigate();
  const portals = getClientData(useQuery, clientOnly, {}, "Portals");
  const profile = getClientData(useQuery, clientOnly, {}, "Profile");
  const portal = getClientData(useQuery, clientOnly, {}, "Portal");
  const sidebar = getClientData(useQuery, clientOnly, {}, "Sidebar");
  const [_update] = useMutation(editAccount);
  const accountId = getAccountId();
  const cookies = new Cookies();
  const licence = profile?.accountInfo?.licenceInfo?.licenceType;
  useEffect(() => {
    selectData({ ...sidebar, show: true }, "Sidebar");
  }, []);
  useEffect(() => {
    if (profile?.accountInfo?.theme) {
      const theme = profile.accountInfo.theme;
      const themeColor = theme === "BRIGHT" ? "light" : theme.toLowerCase();
      selectData({ ...sidebar, mode: `${themeColor}` }, "Sidebar");
      for (let i = 0; i < document.body.classList.length; i++) {
        if (document.body.classList[i].includes("-mode")) {
          document.body.classList.remove(document.body.classList[i]);
        }
      }
      document.body.classList.add(`${theme.toLowerCase()}-mode`);
    }
  }, []);

  const onSave = (accountId, themeColor) => {
    _update({
      variables: { updates: { theme: themeColor, _id: accountId } },
      onCompleted({ editAccount: { success, message } }) {
        if (!success) return showErrorToast(message);
        return showSuccessToast(message);
      },
    });
  };

  const handleClick = theme => {
    for (let i = 0; i < document.body.classList.length; i++) {
      if (document.body.classList[i].includes("-mode")) {
        document.body.classList.remove(document.body.classList[i]);
      }
    }
    document.body.classList.add(theme);
    const colorSaved = theme.split("-")[0].toUpperCase();
    onSave(accountId, colorSaved);
  };

  const toggleLight = () => {
    handleClick("bright-mode");
    selectData({ ...sidebar, mode: "light" }, "Sidebar");
  };
  const toggleDark = () => {
    handleClick("dark-mode");
    selectData({ ...sidebar, mode: "dark" }, "Sidebar");
  };
  const togglePurple = () => {
    handleClick("purple-mode");
    selectData({ ...sidebar, mode: "purple" }, "Sidebar");
  };
  const toggleGreen = () => {
    handleClick("green-mode");
    selectData({ ...sidebar, mode: "green" }, "Sidebar");
  };
  const toggleBlue = () => {
    handleClick("blue-mode");
    selectData({ ...sidebar, mode: "blue" }, "Sidebar");
  };

  const handleCreateShowroom = () => console.log("callcreate show room fn");
  const handleCreateCoverPages = () => console.log("call create cover page fn");
  const toggleMenu = () => {
    selectData({ ...sidebar, show: !sidebar?.show }, "Sidebar");
  };
  const logout = () => {
    deleteAuthData();
  };
  const switchAccount = portal => {
    let portal_id = portal.split("-")[1];
    const array_portals = Object.values(profile?.accessedPortals);
    const result = array_portals.find(item => item.portalId === portal_id);
    selectData(result, "Portal");
    switchActiveProfile(true, result?.portalId, "portalId");
  };

  useEffect(() => {
    if (values.displayValue === "-100") {
      document.body.classList.add("universalmenu-closed");
    } else if (values.displayValue === "0") {
      document.body.classList.remove("universalmenu-closed");
    }
  }, [values.displayValue]);
  const hasTier = useHasModuleAccess()[2];
  const MASTERHUB_URL = `${LOGIN_URL}`;
  return (
    <UniversalMenu
      {...{
        ...values,
        logout,
        toggleLight,
        toggleDark,
        toggleMenu,
        switchAccount,
        _redirect: navigate,
        handleCreateShowroom,
        handleCreateCoverPages,
        accessedPortals: profile?.accessedPortals || [],
        CURRENT_ORG: `${profile ? profile.accountId : ""}-${
          portal ? portal?.portalId : portals ? portals[0]?.portalId : ""
        }`,
        activeProfile: cookies.get("activeProfile"),
        profile,
        accountRole: profile?.accountRole,
        CURRENT_PORTAL: portal
          ? portal?.portalId
          : portals
          ? portals[0]?.portalId
          : "",
        toggleGreen,
        togglePurple,
        displayValue: sidebar?.show ? "0" : "-100",
        toggleBlue,
        mode: sidebar?.mode || "dark",
        redirectURL: "studio",
        licence,
        hasTier,
      }}
    />
  );
}
UniversalMenuComponent.propTypes = {
  showMenu: PropTypes.bool,
  handleShowMenu: PropTypes.func,
};
