export const getThumbnail = item => {
    switch (true) {
      case item?.includes(".xls" || "spreadsheets" || "xlsx"):
        return "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1709290300/filesthumb/excelthumb_eclao5.jpg";
      case item?.includes("mp4" || "mov" || "wmv" || "ogg" || "webm" || "mpeg" || "quicktime"):
        return "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1709316972/filesthumb/videothumb_genqsx.jpg";
      case item?.includes(".pdf"):
        return "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1709290300/filesthumb/pdfthumb_dd1uj1.jpg";
      case item?.includes(".doc" || ".docx" || ".dotx" || ".docm" || ".odt" || ".rtf" || ".txt" || ".wps"):
        return "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1709290300/filesthumb/wordthumb_ktgxyp.jpg";
      case item?.includes("document"):
        return "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1709290300/filesthumb/googledocthumb_bzlm5x.jpg";
      case item?.includes("application/vnd.ms-powerpoint"):
        return "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1702317981/thumbnails/Frame_41862_mszmkc.png";
      case item?.fileUrl?.includes("google.com/presentation"):
        return "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1702317981/thumbnails/Frame_41862_mszmkc.png";
        case item?.includes(".mp3"):
        return "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1709316972/filesthumb/audiothumb_hajv4t.jpg"
      default:
        return item;
    }
  };
  