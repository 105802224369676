import Banner from "../../../../containers/Templates/Banner/Banner";
import { MainWrapper } from "../../../../containers/Templates/styles";
import Frosted from "../../atoms/Frosted/Frosted";
import Solid from "../../atoms/Solid/Solid";
import styled from "styled-components";
import logo from "assets/images/showroom_white.png";
import { Link } from "react-router-dom";
import { useQuery } from "relay-hooks";
import GetCoverpagesByPortalQuery from "graphql/queries/GetCoverpagesByPortal";
import clientOnly from "graphql/queries/clientOnly";
import { getClientData } from "helpers/queryHandler";
import { useState } from "react";
import UpgradePopup from "components/ui/atoms/UpgradePopup/UpgradePopup";
import { ExamplesSlider } from "containers/Templates/showrooms/examplesSlider";
import { coversExamples } from "containers/Templates/showrooms/data";
import { useHasModuleAccess } from "hooks/useModuleAccess";
import { useTranslation } from "react-i18next";

export const list = [
  {
    id: 1,
    image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1703031197/studio%20visuals/template1_2_nr79kt.png",
  },
  {
    id: 2,
    image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1703701509/studio%20visuals/template2_2_xl3puv.png",
  },
  {
    id: 3,
    image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1703031197/studio%20visuals/template3_2_avmzrm.png",

  },
  {
    id: 4,
    image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1703701508/studio%20visuals/Group_40576_2_jabyni.png",

  },
];

export const ImagesSolid = [
  {
    id: 5,
    image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1703031197/studio%20visuals/template5_1_wu70yo.png",
  },
  {
    id: 6,
    image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1703031197/studio%20visuals/template6_1_h2vsf9.png",
  },
  {
    id: 7,
    image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1703031197/studio%20visuals/expandable3_1_ufn1dr.png",
  },
];

export const Title = styled.h1`
    margin-left: 50%;
    margin-top: 5px;
    font-size: 12px;
    margin-bottom: -12px;

    cursor: pointer;
    transform: translateX(-50%);
    @media screen and (min-height: 722px) {
        font-size: 14px;
        margin-top: 10px;
        margin-bottom: -10px;
    }
    @media screen and (min-height: 1000px) {
        font-size: 32px;
    }
`;

function CoverPage() {
  const { t } = useTranslation();
  const [showLicencePopup, setShowLicencePopup] = useState(false);
  const portal = getClientData(useQuery, clientOnly, {}, "Portal");
  const profile = getClientData(useQuery, clientOnly, {}, "Profile");
  const { data } = useQuery(
    GetCoverpagesByPortalQuery,
    { portalId: portal?.portalId },
    {
      fetchPolicy: "store-or-network",
    },
  );
  const checkLicense = () => {
    const license = profile?.accountInfo?.licenceInfo?.licenceType;
    if (license === "STUDENT" && data?.getCoverpagesByPortalId?.coverpages?.length >= 2) {
      setShowLicencePopup(true);
      return false;
    }
    return true;
  };
  const hasTier = useHasModuleAccess()[2];
  return (
    <>
      <Banner title="customize-and-connect"
              desc="make-your-show-rooms-more-personalized"
              bgImage="https://res.cloudinary.com/dsg2ktuqk/image/upload/v1703033958/studio%20visuals/Group_40590_2_1_xwsloj.png"
              button={!hasTier && data?.getCoverpagesByPortalId?.coverpages?.length >= 6 ? t("go-to-licenses") : false}
      />
      {!hasTier && data?.getCoverpagesByPortalId?.coverpages?.length >= 6 && (
        <ExamplesSlider examples={coversExamples} />
      )}
      {((!hasTier && data?.getCoverpagesByPortalId?.coverpages?.length < 6) || hasTier !== false) && (
        <MainWrapper mt="-6rem"
                     className={`main-wrapper-coverpages ${!hasTier && data?.getCoverpagesByPortalId?.coverpages?.length >= 6 && `liteFull`}`}>
          <Frosted
            list={list}
            title="frosted"
            checkLicense={checkLicense}
          />
          <Solid
            list={ImagesSolid}
            title="solid"
            checkLicense={checkLicense}
          />
        </MainWrapper>)}
      <Link to="/showroom-templates">
        <div
          className={`showrooms-bottom-content coverpages ${!hasTier && data?.getCoverpagesByPortalId?.coverpages?.length >= 6 && `liteFullCovers`}`}>
          <img src={logo} className="banner-logo" alt="banner-logo" />
          <p className="explore-showrooms-bottom">{t("explore-show-rooms")}</p>

        </div>
      </Link>
      {showLicencePopup && <UpgradePopup handleClose={() => setShowLicencePopup(false)} />}
    </>
  );
}

export default CoverPage;