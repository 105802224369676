import { STATIC_URL } from "constants/env";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Thumbnail from "./Thumbnail";
import { VideoPlayer } from "@master-wizr/video-player";
import checkTypes from "helpers/checkTypes";
export default function File({ file, pres, index, type, thumbnail, sharing }) {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const renderSlide = file => {
    if (file?.loading)
      return <img src="/assets/img/loader-1.gif" alt="loading..." />;
    if (
      checkTypes(file?.fileUrl) === "word" ||
      file?.fileType === "application/msword" ||
      file?.fileType === "application/vnd.ms-excel" ||
      file?.fileType === "application/vnd.ms-powerpoint"
    ) {
      return thumbnail ? (
        <Thumbnail
          {...{
            file,
            pres,
            index,
            fileType: file?.fileType,
            sharing,
          }}
        />
      ) : (
        <iframe
          title="iframe"
          loading="lazy"
          src={`https://view.officeapps.live.com/op/embed.aspx?src=${STATIC_URL}${file?.fileUrl}#toolbar=0`}
        />
      );
    }
    if (file?.fileType === "application/pdf") {
      return thumbnail ? (
        <Thumbnail
          {...{
            file,
            pres,
            index,
            fileType: file?.fileType,
          }}
        />
      ) : (
        <Worker
          textLayerRendered={false}
          workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js"
        >
          <Viewer
            fileUrl={`${STATIC_URL}${file.fileUrl}`}
            defaultScale={1}
            plugins={[defaultLayoutPluginInstance]}
          />
        </Worker>
      );
    }
    if (file?.fileType?.includes("video") || file?.fileUrl?.includes(".mp3")) {
      return <VideoPlayer src={`${STATIC_URL}${file.fileUrl}`} type={type} />;
    }
    const isFileUrl =
      file.fileUrl.includes("http://") || file.fileUrl.includes("https://");
    return (
      <LazyLoadImage
        role="presentation"
        src={isFileUrl ? `${file.fileUrl}` : `${STATIC_URL}${file.fileUrl}`}
        alt="pres"
        effect="blur"
      />
    );
  };
  return renderSlide(file);
}
