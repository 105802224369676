import React from 'react'
import styles from './fake.module.scss'

export default function FakeNav() {
  return (
    <div className={styles.fake_nav}>
      <div
        role='application'
        className='tox tox-tinymce tox-tinymce-inline tox-tinymce--toolbar-sticky-off'
        aria-disabled='false'
        style={{
          visibility: 'hidden',
          display: 'flex',
          // background: 'rgb(5, 5, 5)',
          position: 'absolute',
          left: 0,
          top: 360
        }}
      >
        <div className='tox-editor-container'>
          <div
            data-alloy-vertical-dir='toptobottom'
            tabIndex={-1}
            className='tox-editor-header'
            style={{ maxWidth: 1367 }}
          >
            <div
              role='group'
              className='tox-toolbar tox-toolbar--scrolling'
              aria-disabled='false'
            >
              <div
                title=''
                role='toolbar'
                data-alloy-tabstop='true'
                tabIndex={-1}
                className='tox-toolbar__group'
              >
                <button
                  aria-label='Undo'
                  title='Undo'
                  type='button'
                  tabIndex={-1}
                  className='tox-tbtn tox-tbtn--disabled'
                  aria-disabled='true'
                  style={{ width: 26 }}
                >
                  <span className='tox-icon tox-tbtn__icon-wrap'>
                    <svg width={24} height={24} focusable='false'>
                      <path
                        d='M6.4 8H12c3.7 0 6.2 2 6.8 5.1.6 2.7-.4 5.6-2.3 6.8a1 1 0 0 1-1-1.8c1.1-.6 1.8-2.7 1.4-4.6-.5-2.1-2.1-3.5-4.9-3.5H6.4l3.3 3.3a1 1 0 1 1-1.4 1.4l-5-5a1 1 0 0 1 0-1.4l5-5a1 1 0 0 1 1.4 1.4L6.4 8Z'
                        fillRule='nonzero'
                      />
                    </svg>
                  </span>
                </button>
                <button
                  aria-label='Redo'
                  title='Redo'
                  type='button'
                  tabIndex={-1}
                  className='tox-tbtn tox-tbtn--disabled'
                  aria-disabled='true'
                  style={{ width: 26 }}
                >
                  <span className='tox-icon tox-tbtn__icon-wrap'>
                    <svg width={24} height={24} focusable='false'>
                      <path
                        d='M17.6 10H12c-2.8 0-4.4 1.4-4.9 3.5-.4 2 .3 4 1.4 4.6a1 1 0 1 1-1 1.8c-2-1.2-2.9-4.1-2.3-6.8.6-3 3-5.1 6.8-5.1h5.6l-3.3-3.3a1 1 0 1 1 1.4-1.4l5 5a1 1 0 0 1 0 1.4l-5 5a1 1 0 0 1-1.4-1.4l3.3-3.3Z'
                        fillRule='nonzero'
                      />
                    </svg>
                  </span>
                </button>
              </div>
              <div
                title=''
                role='toolbar'
                data-alloy-tabstop='true'
                tabIndex={-1}
                className='tox-toolbar__group'
              >
                <button
                  aria-label='Bold'
                  title='Bold'
                  type='button'
                  tabIndex={-1}
                  className='tox-tbtn'
                  aria-disabled='false'
                  aria-pressed='false'
                  style={{ width: 26 }}
                >
                  <span className='tox-icon tox-tbtn__icon-wrap'>
                    <svg width={24} height={24} focusable='false'>
                      <path
                        d='M7.8 19c-.3 0-.5 0-.6-.2l-.2-.5V5.7c0-.2 0-.4.2-.5l.6-.2h5c1.5 0 2.7.3 3.5 1 .7.6 1.1 1.4 1.1 2.5a3 3 0 0 1-.6 1.9c-.4.6-1 1-1.6 1.2.4.1.9.3 1.3.6s.8.7 1 1.2c.4.4.5 1 .5 1.6 0 1.3-.4 2.3-1.3 3-.8.7-2.1 1-3.8 1H7.8Zm5-8.3c.6 0 1.2-.1 1.6-.5.4-.3.6-.7.6-1.3 0-1.1-.8-1.7-2.3-1.7H9.3v3.5h3.4Zm.5 6c.7 0 1.3-.1 1.7-.4.4-.4.6-.9.6-1.5s-.2-1-.7-1.4c-.4-.3-1-.4-2-.4H9.4v3.8h4Z'
                        fillRule='evenodd'
                      />
                    </svg>
                  </span>
                </button>
                <button
                  aria-label='Italic'
                  title='Italic'
                  type='button'
                  tabIndex={-1}
                  className='tox-tbtn'
                  aria-disabled='false'
                  aria-pressed='false'
                  style={{ width: 26 }}
                >
                  <span className='tox-icon tox-tbtn__icon-wrap'>
                    <svg width={24} height={24} focusable='false'>
                      <path
                        d='m16.7 4.7-.1.9h-.3c-.6 0-1 0-1.4.3-.3.3-.4.6-.5 1.1l-2.1 9.8v.6c0 .5.4.8 1.4.8h.2l-.2.8H8l.2-.8h.2c1.1 0 1.8-.5 2-1.5l2-9.8.1-.5c0-.6-.4-.8-1.4-.8h-.3l.2-.9h5.8Z'
                        fillRule='evenodd'
                      />
                    </svg>
                  </span>
                </button>
                <button
                  aria-label='Underline'
                  title='Underline'
                  type='button'
                  tabIndex={-1}
                  className='tox-tbtn'
                  aria-disabled='false'
                  aria-pressed='false'
                  style={{ width: 34 }}
                >
                  <span className='tox-icon tox-tbtn__icon-wrap'>
                    <svg width={24} height={24} focusable='false'>
                      <path
                        d='M16 5c.6 0 1 .4 1 1v5.5a4 4 0 0 1-.4 1.8l-1 1.4a5.3 5.3 0 0 1-5.5 1 5 5 0 0 1-1.6-1c-.5-.4-.8-.9-1.1-1.4a4 4 0 0 1-.4-1.8V6c0-.6.4-1 1-1s1 .4 1 1v5.5c0 .3 0 .6.2 1l.6.7a3.3 3.3 0 0 0 2.2.8 3.4 3.4 0 0 0 2.2-.8c.3-.2.4-.5.6-.8l.2-.9V6c0-.6.4-1 1-1ZM8 17h8c.6 0 1 .4 1 1s-.4 1-1 1H8a1 1 0 0 1 0-2Z'
                        fillRule='evenodd'
                      />
                    </svg>
                  </span>
                </button>
              </div>
              <div
                title=''
                role='toolbar'
                data-alloy-tabstop='true'
                tabIndex={-1}
                className='tox-toolbar__group'
              >
                <button
                  aria-label='Align left'
                  title='Align left'
                  type='button'
                  tabIndex={-1}
                  className='tox-tbtn'
                  aria-disabled='false'
                  aria-pressed='false'
                  style={{ width: 26 }}
                >
                  <span className='tox-icon tox-tbtn__icon-wrap'>
                    <svg width={24} height={24} focusable='false'>
                      <path
                        d='M5 5h14c.6 0 1 .4 1 1s-.4 1-1 1H5a1 1 0 1 1 0-2Zm0 4h8c.6 0 1 .4 1 1s-.4 1-1 1H5a1 1 0 1 1 0-2Zm0 8h8c.6 0 1 .4 1 1s-.4 1-1 1H5a1 1 0 0 1 0-2Zm0-4h14c.6 0 1 .4 1 1s-.4 1-1 1H5a1 1 0 0 1 0-2Z'
                        fillRule='evenodd'
                      />
                    </svg>
                  </span>
                </button>
                <button
                  aria-label='Align center'
                  title='Align center'
                  type='button'
                  tabIndex={-1}
                  className='tox-tbtn'
                  aria-disabled='false'
                  aria-pressed='false'
                  style={{ width: 26 }}
                >
                  <span className='tox-icon tox-tbtn__icon-wrap'>
                    <svg width={24} height={24} focusable='false'>
                      <path
                        d='M5 5h14c.6 0 1 .4 1 1s-.4 1-1 1H5a1 1 0 1 1 0-2Zm3 4h8c.6 0 1 .4 1 1s-.4 1-1 1H8a1 1 0 1 1 0-2Zm0 8h8c.6 0 1 .4 1 1s-.4 1-1 1H8a1 1 0 0 1 0-2Zm-3-4h14c.6 0 1 .4 1 1s-.4 1-1 1H5a1 1 0 0 1 0-2Z'
                        fillRule='evenodd'
                      />
                    </svg>
                  </span>
                </button>
                <button
                  aria-label='Align right'
                  title='Align right'
                  type='button'
                  tabIndex={-1}
                  className='tox-tbtn'
                  aria-disabled='false'
                  aria-pressed='false'
                  style={{ width: 26 }}
                >
                  <span className='tox-icon tox-tbtn__icon-wrap'>
                    <svg width={24} height={24} focusable='false'>
                      <path
                        d='M5 5h14c.6 0 1 .4 1 1s-.4 1-1 1H5a1 1 0 1 1 0-2Zm6 4h8c.6 0 1 .4 1 1s-.4 1-1 1h-8a1 1 0 0 1 0-2Zm0 8h8c.6 0 1 .4 1 1s-.4 1-1 1h-8a1 1 0 0 1 0-2Zm-6-4h14c.6 0 1 .4 1 1s-.4 1-1 1H5a1 1 0 0 1 0-2Z'
                        fillRule='evenodd'
                      />
                    </svg>
                  </span>
                </button>
              </div>
              <div
                title=''
                role='toolbar'
                data-alloy-tabstop='true'
                tabIndex={-1}
                className='tox-toolbar__group'
              >
                <button
                  title='Font Raleway'
                  aria-label='Font Raleway'
                  aria-haspopup='true'
                  type='button'
                  tabIndex={-1}
                  className='tox-tbtn tox-tbtn--select tox-tbtn--bespoke'
                  style={{ userSelect: 'none', width: 70 }}
                  aria-expanded='false'
                >
                  <span className='tox-tbtn__select-label'>Raleway</span>
                  <div className='tox-tbtn__select-chevron'>
                    <svg width={10} height={10} focusable='false'>
                      <path
                        d='M8.7 2.2c.3-.3.8-.3 1 0 .4.4.4.9 0 1.2L5.7 7.8c-.3.3-.9.3-1.2 0L.2 3.4a.8.8 0 0 1 0-1.2c.3-.3.8-.3 1.1 0L5 6l3.7-3.8Z'
                        fillRule='nonzero'
                      />
                    </svg>
                  </div>
                </button>
              </div>
              <div
                title=''
                role='toolbar'
                data-alloy-tabstop='true'
                tabIndex={-1}
                className='tox-toolbar__group'
              >
                <button
                  title='Font size 34px'
                  aria-label='Font size 34px'
                  aria-haspopup='true'
                  type='button'
                  tabIndex={-1}
                  className='tox-tbtn tox-tbtn--select tox-tbtn--bespoke'
                  style={{ userSelect: 'none', width: 70 }}
                  aria-expanded='false'
                >
                  <span className='tox-tbtn__select-label'>34px</span>
                  <div className='tox-tbtn__select-chevron'>
                    <svg width={10} height={10} focusable='false'>
                      <path
                        d='M8.7 2.2c.3-.3.8-.3 1 0 .4.4.4.9 0 1.2L5.7 7.8c-.3.3-.9.3-1.2 0L.2 3.4a.8.8 0 0 1 0-1.2c.3-.3.8-.3 1.1 0L5 6l3.7-3.8Z'
                        fillRule='nonzero'
                      />
                    </svg>
                  </div>
                </button>
              </div>
              <div
                title=''
                role='toolbar'
                data-alloy-tabstop='true'
                tabIndex={-1}
                className='tox-toolbar__group'
              >
                <button
                  aria-label='Insert/edit link'
                  title='Insert/edit link'
                  type='button'
                  tabIndex={-1}
                  className='tox-tbtn'
                  aria-disabled='false'
                  aria-pressed='false'
                  style={{ width: 34 }}
                >
                  <span className='tox-icon tox-tbtn__icon-wrap'>
                    <svg width={24} height={24} focusable='false'>
                      <path
                        d='M6.2 12.3a1 1 0 0 1 1.4 1.4l-2 2a2 2 0 1 0 2.6 2.8l4.8-4.8a1 1 0 0 0 0-1.4 1 1 0 1 1 1.4-1.3 2.9 2.9 0 0 1 0 4L9.6 20a3.9 3.9 0 0 1-5.5-5.5l2-2Zm11.6-.6a1 1 0 0 1-1.4-1.4l2-2a2 2 0 1 0-2.6-2.8L11 10.3a1 1 0 0 0 0 1.4A1 1 0 1 1 9.6 13a2.9 2.9 0 0 1 0-4L14.4 4a3.9 3.9 0 0 1 5.5 5.5l-2 2Z'
                        fillRule='nonzero'
                      />
                    </svg>
                  </span>
                </button>
              </div>
              <div
                title=''
                role='toolbar'
                data-alloy-tabstop='true'
                tabIndex={-1}
                className='tox-toolbar__group'
              >
                <div
                  aria-pressed='false'
                  aria-label='Text color Black'
                  title='Text color Black'
                  role='button'
                  aria-haspopup='true'
                  tabIndex={-1}
                  className='tox-split-button'
                  style={{ userSelect: 'none' }}
                  aria-disabled='false'
                  aria-expanded='false'
                  aria-describedby='aria_1777870673271702660464330'
                >
                  <span
                    role='presentation'
                    className='tox-tbtn'
                    aria-disabled='false'
                    style={{ width: 34 }}
                  >
                    <span className='tox-icon tox-tbtn__icon-wrap'>
                      <svg width={24} height={24} focusable='false'>
                        <g fillRule='evenodd'>
                          <path
                            className='tox-icon-text-color__color'
                            d='M3 18h18v3H3z'
                            fill='#000000'
                          />
                          <path d='M8.7 16h-.8a.5.5 0 0 1-.5-.6l2.7-9c.1-.3.3-.4.5-.4h2.8c.2 0 .4.1.5.4l2.7 9a.5.5 0 0 1-.5.6h-.8a.5.5 0 0 1-.4-.4l-.7-2.2c0-.3-.3-.4-.5-.4h-3.4c-.2 0-.4.1-.5.4l-.7 2.2c0 .3-.2.4-.4.4Zm2.6-7.6-.6 2a.5.5 0 0 0 .5.6h1.6a.5.5 0 0 0 .5-.6l-.6-2c0-.3-.3-.4-.5-.4h-.4c-.2 0-.4.1-.5.4Z' />
                        </g>
                      </svg>
                    </span>
                  </span>
                  <span
                    role='presentation'
                    className='tox-tbtn tox-split-button__chevron'
                    aria-disabled='false'
                  >
                    <svg width={10} height={10} focusable='false'>
                      <path
                        d='M8.7 2.2c.3-.3.8-.3 1 0 .4.4.4.9 0 1.2L5.7 7.8c-.3.3-.9.3-1.2 0L.2 3.4a.8.8 0 0 1 0-1.2c.3-.3.8-.3 1.1 0L5 6l3.7-3.8Z'
                        fillRule='nonzero'
                      />
                    </svg>
                  </span>
                  <span
                    aria-hidden='true'
                    style={{ display: 'none' }}
                    id='aria_1777870673271702660464330'
                  >
                    To open the popup, press Shift+Enter
                  </span>
                </div>
                <div
                  aria-pressed='false'
                  aria-label='Background color Black'
                  title='Background color Black'
                  role='button'
                  aria-haspopup='true'
                  tabIndex={-1}
                  className='tox-split-button'
                  style={{ userSelect: 'none' }}
                  aria-disabled='false'
                  aria-expanded='false'
                  aria-describedby='aria_2996134013281702660464331'
                >
                  <span
                    role='presentation'
                    className='tox-tbtn'
                    aria-disabled='false'
                    style={{ width: 34 }}
                  >
                    <span className='tox-icon tox-tbtn__icon-wrap'>
                      <svg width={24} height={24} focusable='false'>
                        <g fillRule='evenodd'>
                          <path
                            className='tox-icon-highlight-bg-color__color'
                            d='M3 18h18v3H3z'
                            fill='#000000'
                          />
                          <path
                            fillRule='nonzero'
                            d='M7.7 16.7H3l3.3-3.3-.7-.8L10.2 8l4 4.1-4 4.2c-.2.2-.6.2-.8 0l-.6-.7-1.1 1.1zm5-7.5L11 7.4l3-2.9a2 2 0 0 1 2.6 0L18 6c.7.7.7 2 0 2.7l-2.9 2.9-1.8-1.8-.5-.6'
                          />
                        </g>
                      </svg>
                    </span>
                  </span>
                  <span
                    role='presentation'
                    className='tox-tbtn tox-split-button__chevron'
                    aria-disabled='false'
                  >
                    <svg width={10} height={10} focusable='false'>
                      <path
                        d='M8.7 2.2c.3-.3.8-.3 1 0 .4.4.4.9 0 1.2L5.7 7.8c-.3.3-.9.3-1.2 0L.2 3.4a.8.8 0 0 1 0-1.2c.3-.3.8-.3 1.1 0L5 6l3.7-3.8Z'
                        fillRule='nonzero'
                      />
                    </svg>
                  </span>
                  <span
                    aria-hidden='true'
                    style={{ display: 'none' }}
                    id='aria_2996134013281702660464331'
                  >
                    To open the popup, press Shift+Enter
                  </span>
                </div>
              </div>
            </div>
            <div className='tox-anchorbar' />
          </div>
        </div>
        <div
          aria-hidden='true'
          className='tox-throbber'
          style={{ display: 'none' }}
        />
      </div>
    </div>
  )
}
