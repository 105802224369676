export const backgroundImages = [
    "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1694889607/The_Contemporary_Fusion_Decor_Of_Living_Room_Interior_ofyxmh.jpg",
    "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1694890058/Contemporary_Interior_Design___Vancouver_hbp3td.jpg",
    "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1694912372/Apartment_in_Korolev_birvx1.jpg",
    "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1694912349/Luxury_dream_home_designed_for_entertaining_in_San_Francisco_Bay_Area_shpmd0.png",
    "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1694912334/reutov_design_infuses_apartment_proposal_in_new_york_with_pink_and_green_hues_krjjea.jpg",
    "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1694912333/Tour_a_beautiful_modern_home_with_soothing_interiors_in_Sag_Harbor_1_i0w6xf.jpg",
    "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1694914721/Best_Teal_Paint_Colors_and_Why_It_s_Trending_ivrxoe.jpg",
    "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1694951068/bohemian_style_fc6hmo.jpg",
    "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1694958859/a95b8c153410253.632f20e2d8546_k1q5qm.jpg",
    "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1694958858/bed8c395876141.5ea189704c88a_lvibus.jpg",
    "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1694958858/3b5d3a161334243.63c3ccc9b88c9_l9xlvc.jpg",
    "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1694958858/1acae595876141.5ea189704d0ab_x69bzp.jpg",
    "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1694958857/2c3fa695876141.5ea189704dcda_prabja.jpg",
  ];

  export const MPUBLISHER_IMAGES = [
    {
      image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1715344147/Templates/Rectangle_42155_gewgjc.png",
    },
    {
      image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1715344147/Templates/Rectangle_42159_knrhzt.png",
    },
    {
      image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1715344147/Templates/Rectangle_42152_rnzmlv.png",
    },
    {
      image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1715344147/Templates/Rectangle_42154_sxgsae.png",
    },
    {
      image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1715344146/Templates/Rectangle_42149_atamsy.png",
    },
    {
      image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1715344146/Templates/Rectangle_42153_ttwvem.png",
    },
    {
      image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1715344146/Templates/Rectangle_42158_np8igo.png",
    },
    {
      image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1715344146/Templates/Rectangle_42156_kl3rh4.png",
    },
    {
      image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1715344146/Templates/Rectangle_42157_zagj42.png",
    },
    {
      image: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1715344146/Templates/Rectangle_42150_iijpya.png",
    },
  ]