/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type LICENCE_AND_CATEGORY_OPTIONS = "OPTION1" | "OPTION2" | "OPTION3" | "OPTION4" | "%future added value";
export type LICENCE_STATE = "invoiced" | "monthly" | "trial" | "yearly" | "%future added value";
export type LOCALE = "BN" | "DE" | "EN" | "ES" | "FR" | "NO" | "PT" | "SQ" | "%future added value";
export type ROLES = "ADMINISTRATOR" | "CREATOR" | "EDITOR" | "PUBLISHER" | "SUPERADMINISTRATOR" | "VIEWER" | "%future added value";
export type THEME = "BLUE" | "BRIGHT" | "DARK" | "GREEN" | "PURPLE" | "%future added value";
export type TIER = "LITE" | "REGULAR" | "%future added value";
export type profilesByUserIdQueryVariables = {|
  profilesByUserIdId: string
|};
export type profilesByUserIdQueryResponse = {|
  +profilesByUserId: ?{|
    +profiles?: $ReadOnlyArray<{|
      +_id: string,
      +userId: string,
      +accountId: string,
      +accountRole: ROLES,
      +profilePhoto: ?string,
      +locale: ?LOCALE,
      +accountInfo: ?{|
        +_id: string,
        +name: string,
        +theme: ?THEME,
        +licenceInfo: ?{|
          +isActive: boolean,
          +licenceCategories: $ReadOnlyArray<{|
            +dateOfExpiration: any,
            +isActive: boolean,
            +discount: {|
              +validity: ?any
            |},
            +licenceState: LICENCE_STATE,
            +modules: $ReadOnlyArray<?string>,
            +name: LICENCE_AND_CATEGORY_OPTIONS,
            +tier: TIER,
          |}>,
          +licenceType: LICENCE_AND_CATEGORY_OPTIONS,
          +teams: ?$ReadOnlyArray<{|
            +_id: string,
            +modules: ?$ReadOnlyArray<string>,
            +name: string,
            +seats: number,
            +tier: TIER,
          |}>,
        |},
      |},
      +userInfo: {|
        +_id: string,
        +firstName: string,
        +lastName: string,
        +email: string,
      |},
      +accessedModules: ?$ReadOnlyArray<?{|
        +name: string,
        +role: ?ROLES,
        +tier: ?TIER,
      |}>,
      +accessedPortals: ?$ReadOnlyArray<?{|
        +portalId: string,
        +portalRole: ?ROLES,
        +portalInfo: ?{|
          +_id: string,
          +accountId: string,
          +name: string,
          +creator: string,
          +createdAt: any,
          +updatedAt: ?any,
        |},
      |}>,
    |}>
  |}
|};
export type profilesByUserIdQuery = {|
  variables: profilesByUserIdQueryVariables,
  response: profilesByUserIdQueryResponse,
|};
*/


/*
query profilesByUserIdQuery(
  $profilesByUserIdId: ID!
) {
  profilesByUserId(id: $profilesByUserIdId) {
    __typename
    ... on ListOfProfiles {
      profiles {
        _id
        userId
        accountId
        accountRole
        profilePhoto
        locale
        accountInfo {
          _id
          name
          theme
          licenceInfo {
            isActive
            licenceCategories {
              dateOfExpiration
              isActive
              discount {
                validity
              }
              licenceState
              modules
              name
              tier
            }
            licenceType
            teams {
              _id
              modules
              name
              seats
              tier
            }
          }
        }
        userInfo {
          _id
          firstName
          lastName
          email
        }
        accessedModules {
          name
          role
          tier
        }
        accessedPortals {
          portalId
          portalRole
          portalInfo {
            _id
            accountId
            name
            creator
            createdAt
            updatedAt
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "profilesByUserIdId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "profilesByUserIdId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accountId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isActive",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "modules",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tier",
  "storageKey": null
},
v8 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Profile",
      "kind": "LinkedField",
      "name": "profiles",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "userId",
          "storageKey": null
        },
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accountRole",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "profilePhoto",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "locale",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Account",
          "kind": "LinkedField",
          "name": "accountInfo",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "theme",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Licence",
              "kind": "LinkedField",
              "name": "licenceInfo",
              "plural": false,
              "selections": [
                (v5/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "LicenceCategories",
                  "kind": "LinkedField",
                  "name": "licenceCategories",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "dateOfExpiration",
                      "storageKey": null
                    },
                    (v5/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "DiscountInfo",
                      "kind": "LinkedField",
                      "name": "discount",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "validity",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "licenceState",
                      "storageKey": null
                    },
                    (v6/*: any*/),
                    (v4/*: any*/),
                    (v7/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "licenceType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Team",
                  "kind": "LinkedField",
                  "name": "teams",
                  "plural": true,
                  "selections": [
                    (v2/*: any*/),
                    (v6/*: any*/),
                    (v4/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "seats",
                      "storageKey": null
                    },
                    (v7/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "userInfo",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "email",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ModuleRole",
          "kind": "LinkedField",
          "name": "accessedModules",
          "plural": true,
          "selections": [
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "role",
              "storageKey": null
            },
            (v7/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PortalRoles",
          "kind": "LinkedField",
          "name": "accessedPortals",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "portalId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "portalRole",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Portal",
              "kind": "LinkedField",
              "name": "portalInfo",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                (v3/*: any*/),
                (v4/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "creator",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "createdAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "updatedAt",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ListOfProfiles",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "profilesByUserIdQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "profilesByUserId",
        "plural": false,
        "selections": [
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "profilesByUserIdQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "profilesByUserId",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d438824b58c0d7bc6cbf4181519695e5",
    "id": null,
    "metadata": {},
    "name": "profilesByUserIdQuery",
    "operationKind": "query",
    "text": "query profilesByUserIdQuery(\n  $profilesByUserIdId: ID!\n) {\n  profilesByUserId(id: $profilesByUserIdId) {\n    __typename\n    ... on ListOfProfiles {\n      profiles {\n        _id\n        userId\n        accountId\n        accountRole\n        profilePhoto\n        locale\n        accountInfo {\n          _id\n          name\n          theme\n          licenceInfo {\n            isActive\n            licenceCategories {\n              dateOfExpiration\n              isActive\n              discount {\n                validity\n              }\n              licenceState\n              modules\n              name\n              tier\n            }\n            licenceType\n            teams {\n              _id\n              modules\n              name\n              seats\n              tier\n            }\n          }\n        }\n        userInfo {\n          _id\n          firstName\n          lastName\n          email\n        }\n        accessedModules {\n          name\n          role\n          tier\n        }\n        accessedPortals {\n          portalId\n          portalRole\n          portalInfo {\n            _id\n            accountId\n            name\n            creator\n            createdAt\n            updatedAt\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd87404777cc5681e6aae2f2901eb93fe';

module.exports = node;
