import template1 from "../assets/images/template1.png";
import template2 from "../assets/images/template2.png";
import template3 from "../assets/images/template3.png";
import template4 from "../assets/images/template4.png";
import template5 from "../assets/images/template5.png";
import template6 from "../assets/images/template6.png";
import template7 from "../assets/images/template9.png";
import expandable3 from "../assets/images/expandable3.png";
import covert2 from "../assets/images/Studio-banner.jpg";
import template8 from "../assets/images/template8.png";
import template9 from "../assets/images/template9.png";
import template10 from "../assets/images/template10.png";
import template11 from "../assets/images/template11.png";

export const showroomTemplates = [
  { id: 1, url: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1701115087/studio%20visuals%202/Group_40582_m4wkth.png", nrOfRows: 1, numberOfItems: 2 },
  { id: 2, url: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1701115088/studio%20visuals%202/Group_40583_ntfzck.png", nrOfRows: 1, numberOfItems: 3 },
  { id: 3, url: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1701115088/studio%20visuals%202/Group_40584_tmf4rt.png", nrOfRows: 2, numberOfItems: 3 },
  { id: 4, url: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1701115088/studio%20visuals%202/Group_40576_v1cde6.png", nrOfRows: 3, numberOfItems: 4 },
  { id: 8, url: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1701115516/studio%20visuals%202/Group_40573_ugmv6g.png", nrOfRows: 1, numberOfItems: 2 },
  { id: 9, url: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1701115088/studio%20visuals%202/Group_40588_y7ojrv.png", nrOfRows: 1, numberOfItems: 3 },
  { id: 10, url: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1701115087/studio%20visuals%202/Group_40575_cvoz7w.png", nrOfRows: 1, numberOfItems: 4 },
  { id: 11, url: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1701115087/studio%20visuals%202/Group_40581_jirh4b.png", nrOfRows: 2, numberOfItems: 3 },
  {
    id: 5,
    url: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1701115088/studio%20visuals%202/Group_40585_thicvt.png",
    numberOfItems: 2,
    headerImage:"https://res.cloudinary.com/dsg2ktuqk/image/upload/v1694206160/studio%20visuals%202/Frame_40904_1_1_1_dxhyuv.png",
    nrOfRows: 1,
  },
  {
    id: 6,
    url: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1701115088/studio%20visuals%202/Group_40586_s7yowe.png",
    numberOfItems: 3,
    headerImage:"https://res.cloudinary.com/dsg2ktuqk/image/upload/v1694206160/studio%20visuals%202/Frame_40904_1_1_1_dxhyuv.png",
    nrOfRows: 1,
  },
  {
    id: 7,
    url: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1701115522/studio%20visuals%202/Group_40587_qwdfmh.png",
    numberOfItems: 5,
    headerImage: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1694206160/studio%20visuals%202/Frame_40904_1_1_1_dxhyuv.png",
    nrOfRows: 1,
  },
  { id: 12, url: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1707495628/Group_1321314336_ivqyts.png", nrOfRows: 1, numberOfItems: 1 },
  { id: 13, url: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1707495624/Group_1321314337_qs54is.png", nrOfRows: 1, numberOfItems: 1 },
  { id: 14, url: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1707495620/Group_1321314338_ipbmt7.png", nrOfRows: 1, numberOfItems: 1 },
  { id: 15, url: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1707495616/Group_1321314339_sndjyj.png", nrOfRows: 1, numberOfItems: 1 },
  { id: 16, url: "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1709760215/Group_1321314342_mfvsnr.png", nrOfRows: 1, numberOfItems: 1 }, 
];
