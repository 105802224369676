import checkTypes from 'helpers/checkTypes';
import './ShowroomCard.scss';
import { thumbnailGenerator } from 'helpers/thumbnailGenerator';
import {STATIC_URL} from "../../../../constants/env"
import { showroomTemplates } from 'constants/templates';
import videoIcon from '../../../../assets/images/video-player-icon.jpg';
import { ReactComponent as ArrowRight } from "assets/images/icon-arrow-right.svg";
import { ReactComponent as ArrowLeft } from "../../../../assets/images/icon-arrow-left.svg";
import File from '../Presentations/File';
export const ShowroomCard = ({ onClick, item, typeOfCard, microsite }) => {
  
  const { content, templateId, headerImage, backgroundImage } = item;

  const layoutMaker = type => {
    switch (type) {
      case 'template':
        return `showroom-card-row template  single-card-thumb-${templateId}`;
      case 'portrait':
        return "showroom-card-row portrait";
      case 'expandable':
        return `expandable expandable-template-${templateId}`;
        case 'single':
          return `showroom-card-row single-thumbnail-${templateId}`;
        case 'vertical':
          return `showroom-card-row vertical-thumbnail-${templateId}`;
      default:
        return;
    }
  }

  const heightLayoutMaker = type => {
    switch (type) {
      case 'template':
        return { height: `${100 / content.length - 2}%` };
      case 'portrait':
        return { height: `${80 / content.length}%` };
        case 'vertical':
          return { height: `${80 / content.length}%` };
      default:
        return {};
    }
  }

  const coloumnsChecker = () => {
    if(microsite===true){
      return 1;
    }
    if (templateId) {
      switch (parseInt(templateId)) {
        case 1:
          return 2;
        case 2:
          return 3;
        case 3:
          return 3;
        case 4:
          return 4;
        case 5:
          return 2;
        case 6:
          return 3;
        case 7:
          return 5;
        case 8:
          return 2;
        case 9:
          return 3;
        case 10:
          return 4;
        case 11:
          return 3;
        case 12:
          return 1;
        case 13:
          return 1;
        case 14:
          return 1;
        case 15:
          return 1;
          case 16:
            return 1;
        default:
          return 2;
      }
    }
  }

  return (
    <div
      className={`showroom-card-container showroom-card-container${coloumnsChecker()} showroom-card-container-single-${templateId}
      ${backgroundImage ? "showroom-card-container-background" : ""} 
      `}
      onClick={onClick}
      style={{backgroundImage: `url("${backgroundImage}")`}}
    >
      {!microsite?content.map((row, index) => (
        <div
          key={index}
          className={layoutMaker(typeOfCard)}
          style={heightLayoutMaker(typeOfCard)}
        >
          {typeOfCard === "template" && templateId===14 &&
           <div className='showroom14-arrows'>
            <ArrowLeft className='arrow-left-thumb' />
            <ArrowRight className='arrow-right-thumb'/>
            </div>
          }
           {typeOfCard === "template" && templateId===15 && row?.content?.[0]?.files?.length>=2 &&
           <div className='showroom-bottom-thumbnails'>
            <div className='bottom-thumbnails'>
              {row?.content?.[0]?.files?.slice(0,3)?.map((item,i) =>{
                return(
                  <File thumbnail={true} file={item} index={i} />
                )
              })}
              </div>
            </div>
          }
          {typeOfCard === "template" &&
            row?.content?.slice(0, coloumnsChecker()).map((imgItem, index) => (
              <div
                className={checkTypes(imgItem?.imageUrl) !== "image" ? ` template template-thumbnail-${coloumnsChecker()} docImage` : `template template-thumbnail-${coloumnsChecker()}`}
                style={{
                  height: 73,
                  aspectRatio: 16 / 9,
                  // minWidth: `${(coloumnsChecker() === 2 ? 90 : coloumnsChecker() === 3 ? 85 : 80) / coloumnsChecker()}%`,
                  // maxWidth: `${(coloumnsChecker() === 2 ? 90 : coloumnsChecker() === 3 ? 85 : 80) / coloumnsChecker()}%`,
                  backgroundImage: `url("${
                    !imgItem?.imageUrl.includes("showroompresentation-JUo2mM")?
                    imgItem?.presentation?.coverUrl === ""
                      ? checkTypes(imgItem?.imageUrl) !== "image"
                        ? thumbnailGenerator(checkTypes(imgItem?.imageUrl)) ===
                            "video" ||
                          thumbnailGenerator(checkTypes(imgItem?.imageUrl)) ===
                            "links"
                          ? videoIcon
                          : checkTypes(imgItem?.imageUrl) === "audio" ?
                          "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1707428596/studio%20visuals/Frame_41939_sn0urm.png" :
                          thumbnailGenerator(checkTypes(imgItem?.imageUrl))
                        : imgItem?.imageUrl.replace(/\s/g, "%20")
                      : imgItem?.presentation?.coverUrl?.includes("/Covers/") ? imgItem?.presentation?.coverUrl : STATIC_URL + imgItem?.presentation?.coverUrl: 
                    STATIC_URL+imgItem?.imageUrl.replace(/\s/g, "%20")
                  }")`,
                }}
                key={index}
              ></div>
            ))}
              {typeOfCard === "vertical" &&
            row?.content?.slice(0, coloumnsChecker()).map((imgItem, index) => (
              <div
                className={checkTypes(imgItem?.imageUrl) !== "image" ? ` template template-thumbnail-${coloumnsChecker()} docImage` : `template template-thumbnail-${coloumnsChecker()}`}
                style={{
                  height: 73,
                  aspectRatio: 16 / 9,
                  backgroundImage: `url("${
                    !imgItem?.imageUrl.includes("showroompresentation-JUo2mM")?
                    imgItem?.presentation?.coverUrl === ""
                      ? checkTypes(imgItem?.imageUrl) !== "image"
                        ? thumbnailGenerator(checkTypes(imgItem?.imageUrl)) ===
                            "video" ||
                          thumbnailGenerator(checkTypes(imgItem?.imageUrl)) ===
                            "links"
                          ? videoIcon
                          : checkTypes(imgItem?.imageUrl) === "audio" ?
                          "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1709316972/filesthumb/audiothumb_hajv4t.jpg" :
                          thumbnailGenerator(checkTypes(imgItem?.imageUrl))
                        : imgItem?.imageUrl.replace(/\s/g, "%20")
                      : imgItem?.presentation?.coverUrl?.includes("/Covers/") ? imgItem?.presentation?.coverUrl : STATIC_URL + imgItem?.presentation?.coverUrl: 
                    STATIC_URL+imgItem?.imageUrl.replace(/\s/g, "%20")
                  }")`,
                }}
                key={index}
              ></div>
            ))}
            {templateId===16 &&
            <div className="vertical-arrows">
              <ArrowLeft />
              <ArrowRight/>
              </div>
            }
          {typeOfCard === "portrait" &&
            row?.content?.slice(0, coloumnsChecker()).map((imgItem, index) => (
              <div
                className={checkTypes(imgItem?.imageUrl) !== "image" ? `portraitImg portraitImg${coloumnsChecker()} docImage ${templateId===10 && `portraitImg10`}` : `portraitImg portraitImg${coloumnsChecker()} ${templateId===11 && `portraitImg11`} ${templateId===10 && `portraitImg10`}`}
                key={index}
                style={{
                  backgroundImage: `url("${
                    !imgItem?.imageUrl.includes("showroompresentation-JUo2mM")?
                    imgItem?.presentation?.coverUrl === ""
                      ? checkTypes(imgItem?.imageUrl) !== "image"
                        ? thumbnailGenerator(checkTypes(imgItem?.imageUrl)) ===
                            "video" ||
                          thumbnailGenerator(checkTypes(imgItem?.imageUrl)) ===
                            "links"
                          ? videoIcon
                          : thumbnailGenerator(checkTypes(imgItem?.imageUrl))
                        : imgItem?.imageUrl.replace(/\s/g, "%20")
                      : STATIC_URL + imgItem?.presentation?.coverUrl
                    : STATIC_URL+imgItem?.imageUrl.replace(/\s/g, "%20")
                  }")`,
                }}
              ></div>
            ))}

          {typeOfCard === "expandable" && (
            <>
              <div
                className={`expandableImgHeader expandable-thumbnail-${coloumnsChecker()}`}
                style={{
                  height: templateId > 6 ? '75px' : '55px',
                  backgroundImage: `url("${
                    headerImage
                      ? STATIC_URL+headerImage
                      : showroomTemplates.filter(
                          item => item.id === parseInt(templateId)
                        )[0].headerImage
                  }")`,
                }}
              ></div>
              <div
                className="expandableRow"
              >
                {row?.content
                  ?.slice(0, coloumnsChecker())
                  .map((imgItem, index) => (
                    <div 
                      className={checkTypes(imgItem?.imageUrl) !== "image" ? `expandableImg expandable-thumbnail-img${coloumnsChecker()} docImage` : `expandableImg expandable-thumbnail-img${coloumnsChecker()}`}
                      key={index}
                      style={{
                        height: "60px",
                        aspectRatio: 16 / 9,
                        // minWidth: `${(coloumnsChecker() === 2 ? 92 : coloumnsChecker() === 3 ? 88 : 80) / coloumnsChecker()}%`,
                        // maxWidth: `${(coloumnsChecker() === 2 ? 92 : coloumnsChecker() === 3 ? 88 : 80) / coloumnsChecker()}%`,
                        backgroundImage: `url("${
                          !imgItem?.imageUrl.includes("showroompresentation-JUo2mM")?
                          imgItem?.presentation?.coverUrl === ""
                            ? checkTypes(imgItem?.imageUrl) !== "image"
                              ? thumbnailGenerator(checkTypes(imgItem?.imageUrl)) ===
                                  "video" ||
                                thumbnailGenerator(checkTypes(imgItem?.imageUrl)) ===
                                  "links"
                                ? videoIcon
                                : thumbnailGenerator(checkTypes(imgItem?.imageUrl))
                              : imgItem?.imageUrl.replace(/\s/g, "%20")
                            : STATIC_URL + imgItem?.presentation?.coverUrl
                          : STATIC_URL+imgItem?.imageUrl.replace(/\s/g, "%20")
                        }")`,
                      }}
                    ></div>
                  ))}
              </div>
            </>
          )}
        </div>
      )):
      <div
      className={checkTypes(item?.thumbnailUrl) !== "image" ? `template template-thumbnail-${coloumnsChecker()} docImage` : `template-thumbnail-${coloumnsChecker()}`}
      style={{
        height: 70,
        aspectRatio: 16 / 9,
        backgroundImage: `url("${item.thumbnailUrl}")`,
      }}
      key={item._id}
    ></div>}
    </div>
  );
}
