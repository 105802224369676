import graphql from "babel-plugin-relay/macro";

const profilesByUserId = graphql`
  query profilesByUserIdQuery($profilesByUserIdId: ID!) {
    profilesByUserId(id: $profilesByUserIdId) {
      ... on ListOfProfiles {
        profiles {
          _id
          userId
          accountId
          accountRole
          profilePhoto
          locale
          accountInfo {
            _id
            name
            theme
            licenceInfo {
              isActive
              licenceCategories {
                dateOfExpiration
                isActive
                discount {
                  validity
                }
                licenceState
                modules
                name
                tier
              }
              licenceType
              teams {
                _id
                modules
                name
                seats
                tier
              }
            }
          }
          userInfo {
            _id
            firstName
            lastName
            email
          }
          accessedModules {
            name
            role
            tier
          }
          accessedPortals {
            portalId
            portalRole
            portalInfo {
              _id
              accountId
              name
              # menus {
              #   _id
              #   name
              #   accountId
              #   portalId
              #   position
              #   folders {
              #     _id
              #     name
              #     accountId
              #     menuId
              #     portalId
              #     slug
              #     position
              #     presentations {
              #       _id
              #       folderId
              #       menuId
              #       portalId
              #       accountId
              #       name
              #       slug
              #       position
              #       coverUrl
              #       files {
              #         _id
              #         name
              #         relatedPresentationIds
              #         fileUrl
              #         fileType
              #         coverUrl
              #         thumbnailUrl
              #         audioUrl
              #         visibility
              #         creator
              #         createdAt
              #         updatedAt
              #         links {
              #           presentationId
              #           name
              #           url
              #         }
              #         tags {
              #           presentationId
              #           tag
              #         }
              #         positions {
              #           presentationId
              #           position
              #         }
              #       }
              #       createdAt
              #       updatedAt
              #       showFileNames
              #     }
              #     createdAt
              #     updatedAt
              #   }
              #   slug
              #   visibility
              #   createdAt
              #   updatedAt
              # }
              creator
              createdAt
              updatedAt
            }
          }
        }
      }
    }
  }
`;
export default profilesByUserId;
