import React, { useState } from "react";
import { ReactComponent as TrashCan } from "../../assets/images/trash.svg";
import { STATIC_URL } from "../../constants/env";
import styled from "styled-components";
import { showErrorToast } from "@master-wizr/toast";
import { useTranslation } from "react-i18next";

export const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  .backdrop {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    background-color: rgba(0, 0, 0, 0.442);
    backdrop-filter: blur(26px);
    -webkit-backdrop-filter: blur(26px);
  }
`;

export const ModalWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #2e2e2e;
  max-width: 600px;
  padding-bottom: 0px;
  backdrop-filter: blur(26px);
  -webkit-backdrop-filter: blur(26px);
  img {
    object-fit: cover;
    border-radius: 9px;
  }
  label {
    margin: 15px 0;
    color: white;
    cursor: pointer;
  }
  width: 450px;
  height: 450px;
  border-radius: 18px;
  opacity: 1;
  color: #8d8d8d;
  padding: 25px;
  z-index: 2;
  &.modal-wrapper-expandable {
    width: 700px;
    height: 350px;
  }
  @media screen and (min-height: 900px) {
    width: 500px;
    height: 500px;
  }
  @media screen and (min-width: 2560px) {
    zoom:1.25;
  }
  @media screen and (min-width: 3000px) {
    zoom: 1.65;
    label{
      font-size: 20px;
    }
  }
  @media screen and (min-width: 4000px) {
    width: 850px;
    max-width: 850px;
    height: 800px;
    padding: 30px;
    label{
      font-size: 30px;
    }
  }
`;

export const ButtonsWrapper = styled.div`
  font-size: 18px;
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 7%;
  right: 15px;
`;

export const Button = styled.div`
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  padding: 5px 20px;
  border-radius: 5px;
  color: white !important;
  margin: 0 5px;
  opacity: 0.9;
  &:hover{
    opacity: 1;
  }
  &.cancel-button{
    background-color: rgb(62, 62, 62);
  }
  &:nth-child(2) {
    background-color: #ec7600;
  }
  @media screen and (min-height: 900px) {
    font-size: 18px;
  }
  @media screen and (min-width: 2000px) {
    width:auto;
  }
  @media screen and (min-width: 3000px) {
    font-size: 24px;
    letter-spacing: 1px;
  }
  @media screen and (min-width: 4000px) {
    font-size: 34px;
  }
`;

export const Heading = styled.div`
  font-size: 18px;
  text-align: left;
  margin-bottom: -1rem;
  @media screen and (min-height: 900px) {
    font-size: 20px;
  }
  @media screen and (min-width: 3000px) {
    font-size: 24px;
    letter-spacing: 1px;
  }
  @media screen and (min-width: 4000px) {
    font-size: 34px;
  }
`;

export const InputWrapper = styled.div`
  text-align: left;
  margin-top: 15px;
  @media screen and (min-width: 3000px) {
    margin-bottom: 15px;
  }
  @media screen and (min-width: 4000px) {
    margin-top: 30px;
    margin-bottom: 30px;
    input {
      font-size: 30px !important;
    }
  }
`;

export const ImageWrapper = styled.div`
  height: 16rem;
  width: 100%;
  object-fit: contain;
  background-repeat: no-repeat;
  border: 1px solid rgba(116, 116, 116, 0.2725490196);
  border-radius: 9px;
  background-size: cover;
  background-position: center;
  &.expandable-wrapper{
  border:none;
  }
  @media screen and (min-height: 900px) {
    height: 18rem;
  }
`;

const DeleteButtonWrapper = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 5px;
  opacity: 1;
  position: absolute;
  bottom: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    opacity: 0.9;
  }
  button {
    width: 100%;
  }
  svg {
    opacity: 1;
    g {
      opacity: 1;
      fill: red;
      path {
        fill: red;
        opacity: 1;
      }
    }
  }
`;

const DeleteTooltip = styled.div`
  background: black;
  font-weight: 600;
  width: 160px;
  height: 25px;
  border: 1px solid black;
  border-radius: 5px;
  position: absolute;
  letter-spacing: 0.5px;
  bottom: 22px;
  left: 50%;
  color: white;
  opacity: 1;
  transform: translate(-50%, -50%);
  transition: all 1s ease-in;
  animation: fadeIn 1s;
  p {
    font-size: 14px;
    line-height: 1.5em;
    padding: 1px;
  }
  &::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top-color: black;
    transform: translate(-50%, 0);
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const UploadModal = ({
  closeModal,
  modal,
  setImage,
  image,
  headerRef,
  expandableHeader,
  title,
  thumbnailDeleter,
  loading,
  closeOptions,
  microsite,
  isCreateMode,
  isUploaded
}) => {
  const [usedImage, setUsedImage] = useState(null);
  const [isHovering, setIsHovering] = useState(false);
  let showroomCoverUrl = modal?.data?.imageUrl;
  let coverpageThumbnail = modal?.data?.thumbnail;
  let headerImage = modal?.data?.headerImage;
  let micrositeThumbnail = modal?.data?.micrositeThumbnail;
  let showroomBackground = modal?.data?.backgroundImage;
  let micrositeBackground = modal?.data?.micrositeBackground;
  const { t } = useTranslation();


  let prevImage =
    coverpageThumbnail ||
    headerImage ||
    micrositeThumbnail ||
    (showroomCoverUrl
      ? showroomCoverUrl?.includes("showroompresentation-JUo2mM")
        ? showroomCoverUrl
        : ""
      : "") ||   showroomBackground || micrositeBackground ||
    "";
  const onImageChange = e => {
    const imageThumbnail = e.target.files[0];

    // Check if the selected file is an image
    if (!imageThumbnail || !imageThumbnail.type.startsWith("image/")) {
      showErrorToast("Please select an image file.");
      return;
    }

    imageThumbnail.tempName = imageThumbnail.name;
    imageThumbnail.thumbnailUrl = URL.createObjectURL(imageThumbnail);
    setUsedImage(imageThumbnail);
    // check if we previously changed within this same session
    let wasUpdated = false;

    image.length &&
      image.filter((item, index) => {
        if (item.id === modal.data.id) {
          if (item.itemData.rowIndex !== undefined) {
            if (item.itemData.rowIndex === modal.data.rowIndex) {
              wasUpdated = index;
            }
          } else {
            wasUpdated = index;
          }
        }
      });
    if (wasUpdated !== false) {
      const newImage = image[wasUpdated];
      newImage.blob = imageThumbnail;
      let prevState = image;
      prevState.splice(wasUpdated, 1);
      setImage([...prevState, newImage]);
    } else {
      setImage([
        ...image,
        {
          id: modal?.data?.id,
          blob: imageThumbnail,
          coverUrl: modal?.data?.thumbnail || modal?.data?.coverUrl || "",
          itemData: modal?.data,
        },
      ]);
    }
  };

  const changeThumbnail = () => {
    const presentationElement = !modal.data.rowIndex
      ? document.getElementById(`${modal?.data?.id}`)?.children[0]
      : document.getElementById(
          `${modal?.data?.id}&${modal?.data?.rowIndex.split("&")[0]}`
        )?.children[0];
    if (usedImage) {
      if (
        title !== "Change coverpage background" &&
        presentationElement?.children[0]?.children[0].nodeName === "VIDEO"
      ) {
        const videoNode = presentationElement?.children[0]?.children[0];
        presentationElement.parentNode.style.backgroundImage = `url(${usedImage?.thumbnailUrl.replace(
          /\s/g,
          "%20"
        )})`;
        videoNode.style.visibility = "hidden";
        document.querySelector(".mainplaybtn")?.remove();
      } else if (microsite === true) {
      } else {
        expandableHeader
          ? (headerRef.current.style.backgroundImage = `url(${usedImage?.thumbnailUrl.replace(
              /\s/g,
              "%20"
            )})`)
          : (presentationElement.style.backgroundImage = `url(${usedImage?.thumbnailUrl.replace(
              /\s/g,
              "%20"
            )})`);
      }
    }
    closeModal({ ...modal, open: false });
  };

  const handleDeleteCover = () => {
    if (usedImage) {
      setUsedImage(null);
    } else {
      thumbnailDeleter(prevImage, modal);
    }
  };

  const handleMouseHover = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  return (
    <>
      <Wrapper>
        <div className="backdrop" />
        <ModalWrapper
         className={`modal-wrapper ${expandableHeader ? "modal-wrapper-expandable" : ""}`}
        >
          <Heading className="modal-title">{t(title)}</Heading>
          <InputWrapper>
            <input
              id="file-upload"
              type="file"
              accept="image/*"
              onChange={onImageChange}
            />
            <label htmlFor="file-upload">{t("Choose File")}</label>
          </InputWrapper>
          <ImageWrapper className={expandableHeader && `expandable-wrapper`}>
          {(prevImage !== "" || usedImage?.thumbnailUrl !== undefined) && (
              <div
                style={{
                  width: "100%",
                  height: expandableHeader ? "60%" : "100%",
                  borderRadius: "9px",
                  backgroundImage: `url(${(isCreateMode && headerRef && !usedImage?.thumbnailUrl)
                      ? ""
                      : usedImage?.thumbnailUrl
                        ? usedImage?.thumbnailUrl
                        : prevImage?.includes("https")
                          ? prevImage
                          : STATIC_URL + prevImage
                    })`,
                  backgroundSize: expandableHeader ? "cover" : "contain",
                  backgroundPosition: "center",
                  position: "relative",
                  backgroundRepeat: "no-repeat",
                  border: "1px solid rgba(116, 116, 116, 0.2725490196)",
                  top: expandableHeader ? "6%" : "0",
                }}
              >
                {
                  (isCreateMode && headerRef && !usedImage?.thumbnailUrl) ?
                    ""
                    :
                    <>
                      {isHovering && (
                        <DeleteTooltip>
                          <p>{t("Delete Cover Image")}</p>
                        </DeleteTooltip>
                      )}
                      {prevImage !== "" && !showroomBackground && !micrositeBackground ? (
                        <DeleteButtonWrapper>
                          <button
                            onClick={handleDeleteCover}
                            onMouseOver={handleMouseHover}
                            onMouseOut={handleMouseLeave}
                          >
                            <TrashCan />
                          </button>
                        </DeleteButtonWrapper>
                      ) : (
                        ""
                      )}
                    </>
                }
              </div>
          )}
          </ImageWrapper>
          <ButtonsWrapper>
            <Button
              onClick={
                closeOptions !== undefined
                  ? () => {
                      closeModal({ open: false });
                      closeOptions(false);
                    }
                  : () => {
                      closeModal({ open: false });
                    }
              }
              className="cancel-button"
            >
              {t("Cancel")}
            </Button>
            <Button
              style={{ color: "#ec7600" }}
              onClick={() => changeThumbnail()}
              className={"done"}
            >
              {loading ? `${t("Saving")}...` : t("Done")}
            </Button>
          </ButtonsWrapper>
        </ModalWrapper>
      </Wrapper>
    </>
  );
};

export default UploadModal;
