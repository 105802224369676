export const showroomExamples = [
    "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1705952486/library/Rectangle_16_t2uu3n.jpg",
    "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1705952482/library/Rectangle_9_hzgknr.jpg",
    "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1705952486/library/Rectangle_11_cfbgd9.jpg",
    "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1705952480/library/Rectangle_5_rpcou6.jpg",
    "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1705952483/library/Rectangle_12_cq0ltd.jpg",
    "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1705952483/library/Rectangle_13_t4zl53.jpg",
    "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1705952480/library/Rectangle_6_bdrdtz.jpg"
]

export const coversExamples = [
    "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1711497492/studio%20visuals%202/example11_h58lxw.png",
    "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1711497492/studio%20visuals%202/example22_hidjzj.png",
    "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1711497492/studio%20visuals%202/example33_t5ymke.png",
    "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1711497492/studio%20visuals%202/example55_bs6rxy.png",
    "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1711497492/studio%20visuals%202/example11_h58lxw.png",
    "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1711497492/studio%20visuals%202/example22_hidjzj.png",
    "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1711497492/studio%20visuals%202/example33_t5ymke.png",
    "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1711497492/studio%20visuals%202/example55_bs6rxy.png",
]

export const micrositesExamples = [
    "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1711496866/studio%20visuals%202/example4_stx1bc.png",
    "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1711496867/studio%20visuals%202/example5_b5phth.png",
    "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1711496867/studio%20visuals%202/example6_cegth6.png",
    "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1711496867/studio%20visuals%202/example7_larmbo.png",
    "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1711496866/studio%20visuals%202/example4_stx1bc.png",
    "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1711496867/studio%20visuals%202/example5_b5phth.png",
    "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1711496867/studio%20visuals%202/example6_cegth6.png",
    "https://res.cloudinary.com/dsg2ktuqk/image/upload/v1711496867/studio%20visuals%202/example7_larmbo.png",
]